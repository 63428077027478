import React from 'react';
import { Switch, Route, Redirect, RouteProps } from 'react-router-dom';
import ErrorBoundary from '@/components/ErrorBoundary';

import Administrator from '@/containers/Administrator/Loadable';
import Administrators from '@/containers/Administrators/Loadable';
import BillingInformationSettings from '@/containers/BillingInformationSettings/Loadable';
import BookingSettings from '@/containers/BookingSettings/Loadable';
import BookingPrintoutTemplates from '@/containers/BookingPrintoutTemplates/Loadable';
import BookingPrintoutTemplate from '@/containers/BookingPrintoutTemplate/Loadable';
import BookingsReport from '@/containers/BookingsReport/Loadable';
import ChangePassword from '@/containers/ChangePassword/Loadable';
import Customer from '@/containers/Customer/Loadable';
import Customers from '@/containers/Customers/Loadable';
import Ratings from '@/containers/Ratings/Loadable';
import InactiveCustomers from '@/containers/InactiveCustomers/Loadable';
import CustomField from '@/containers/CustomField/Loadable';
import CustomFields from '@/containers/CustomFields/Loadable';
import Dashboard from '@/containers/Dashboard/Loadable';
import DateSchedule from '@/containers/Schedule/DateSchedule/Loadable';
import DibsForm from '@/containers/DibsForm/Loadable';
import EditBooking from '@/containers/Booking/EditBooking/Loadable';
import ExtraLicenses from '@/containers/ExtraLicenses/Loadable';
import SyncWithCalendar from '@/containers/SyncWithCalendar/Loadable';
import Homepage from '@/containers/Homepage/Loadable';
import HomepageImage from '@/containers/HomepageImage/Loadable';
import HomepageImages from '@/containers/HomepageImages/Loadable';
import HomepagePost from '@/containers/HomepagePost/Loadable';
import HomepagePosts from '@/containers/HomepagePosts/Loadable';
import MessageLog from '@/containers/MessageLog/Loadable';
import MessageTemplate from '@/containers/MessageTemplate/Loadable';
import MessageTemplates from '@/containers/MessageTemplates/Loadable';
import NewsletterTemplate from '@/containers/NewsletterTemplate/Loadable';
import NewsletterTemplates from '@/containers/NewsletterTemplates/Loadable';
import MySubscription from '@/containers/MySubscription/Loadable';
import NewBooking from '@/containers/Booking/NewBooking/Loadable';
import NewsletterLog from '@/containers/NewsletterLog/Loadable';
import Price from '@/containers/Price/Loadable';
import Prices from '@/containers/Prices/Loadable';
import PromoCode from '@/containers/PromoCode/Loadable';
import PromoCodes from '@/containers/PromoCodes/Loadable';
import PromoCodesUsages from '@/containers/PromoCodesUsage/Loadable';
import RecurringSchedule from '@/containers/Schedule/RecurringSchedule/Loadable';
import Resource from '@/containers/Resource/Loadable';
import ResourceType from '@/containers/ResourceType/Loadable';
import Resources from '@/containers/Resources/Loadable';
import SendNewsletter from '@/containers/SendNewsletter/Loadable';
import SetupAccount from '@/containers/SetupAccount/Loadable';
import CreateService from '@/containers/SetupAccount/CreateService';
import Scheduler from '@/containers/Scheduler/Loadable';
import Schedules from '@/containers/Schedules/Loadable';
import Service from '@/containers/Service/Loadable';
import Services from '@/containers/Services/Loadable';
import PaymentSettings from '@/containers/PaymentSettings/Loadable';
import CodelockSettings from '@/containers/CodelockSettings/Loadable';
import SupportMethods from '@/containers/Support/SupportMethods/Loadable';
import SupportManuals from '@/containers/Support/SupportManuals/Loadable';
import SupportTickets from '@/containers/Support/SupportTickets/Loadable';
import SupportTicket from '@/containers/Support/SupportTicket/Loadable';
import SupportTicketThread from '@/containers/Support/SupportTicketThread/Loadable';
import SystemSettings from '@/containers/SystemSettings/Loadable';
import TimeException from '@/containers/TimeException/Loadable';
import TimeExceptions from '@/containers/TimeExceptions/Loadable';
import TimeExceptionsReport from '@/containers/TimeExceptionsReport/Loadable';
import EaccountingArticles from '@/containers/EAPriceArticleMappings';
import EASettings from '@/containers/EASettings';
import Articles from '@/containers/Articles/Loadable';
import Article from '@/containers/Article/Loadable';

import MainLayout from './MainLayout';
import keycloak from '@/keycloak';
import Newsletters from '../Newsletters';
import BookingsMain from '../BookingsMain';
import CompanyMain from '../CompanyMain';
import CreateResourceWizard from '../CreateResourceWizard';
import Transactions from '../TransactionsReport/Loadable';
import PromoCodesValueCards from '../PromoCodesValueCards';
import MailchimpSettings from '../MailchimpSettings/Loadable';



const Test: React.FC = () => {

    return <h2>Test</h2>
}

// All pages have padding except several ones
const CustomRoute: React.FC<
    RouteProps & { padded?: boolean; authenticated?: boolean; component: React.ComponentType | any }
> = ({ component: Component, padded = true, authenticated = true, ...rest }) => (
    keycloak.authenticated ? <Route
        {...rest}
        render={(props) =>
            padded ? (
                <div
                    style={{
                        padding: 20,
                        maxWidth: 1400,
                        width: '100%',
                        flex: '1 0 auto',
                        margin: 'auto',
                    }}
                >
                    <Component {...props} />
                </div>
            ) : (
                <Component {...props} />
            )
        }
    /> : null
);

const Full: React.FC = () => {
    return (
        <ErrorBoundary>
            <MainLayout>
                <Switch>
                    <CustomRoute path="/dashboard" component={Dashboard} />
                    <CustomRoute path="/licenses" component={Dashboard} />
                    <CustomRoute path="/scheduler" component={Scheduler} padded={false} />
                    <CustomRoute path="/company" component={CompanyMain} />

                    <CustomRoute exact path="/services" component={Services} />
                    <CustomRoute exact path="/services/new" component={Service} />
                    <CustomRoute exact path="/services/:id" component={Service} />

                    <Redirect exact from="/administrators" to="/company#administrators" />
                    <CustomRoute exact path="/administrators" component={Administrators} />
                    <CustomRoute exact path="/administrators/new" component={Administrator} />
                    <CustomRoute exact path="/administrators/:id" component={Administrator} />

                    <CustomRoute exact path="/resources" component={Resources} />
                    <CustomRoute exact path="/resource-types/new" component={ResourceType} />
                    <CustomRoute exact path="/resource-types/:id" component={ResourceType} />

                    
                    <CustomRoute exact path="/create-resources-wizard/new" component={CreateResourceWizard} />
                    <CustomRoute exact path="/create-resources-wizard/:id" component={CreateResourceWizard} />

                    <Redirect exact from="/resource-types" to="/resources" /> 
                    <CustomRoute exact path="/resources/new" component={Resource} />
                    <CustomRoute exact path="/resources/:id" component={Resource} />

                    <CustomRoute exact path="/schedules" component={Schedules} />
                    <CustomRoute
                        exact
                        path="/schedules/recurring/new"
                        component={RecurringSchedule}
                    />
                    <CustomRoute
                        exact
                        path="/schedules/recurring/:id"
                        component={RecurringSchedule}
                    />
                    <CustomRoute exact path="/schedules/date/new" component={DateSchedule} />
                    <CustomRoute exact path="/schedules/date/:id" component={DateSchedule} />
                    <Redirect exact path="/schedules/recurring" to="/schedules" />
                    <Redirect exact path="/schedules/date" to="/schedules" />

                    <CustomRoute exact path="/time-exceptions" component={TimeExceptions} />
                    <CustomRoute exact path="/time-exceptions/new" component={TimeException} />
                    <CustomRoute exact path="/time-exceptions/:id" component={TimeException} />
                    <CustomRoute
                        exact
                        path="/time-exceptions-report"
                        component={TimeExceptionsReport}
                    />

                    <CustomRoute exact path="/prices" component={Prices} />
                    <CustomRoute exact path="/prices/new" component={Price} />
                    <CustomRoute exact path="/prices/:id" component={Price} />

                    <CustomRoute exact path="/promo-codes" component={PromoCodes} />
                    <CustomRoute exact path="/promo-codes/new" component={PromoCode} />
                    <CustomRoute exact path="/promo-codes/:id" component={PromoCode} />
                    <CustomRoute exact path="/promo-codes-usages" component={PromoCodesUsages} />
                    <CustomRoute exact path="/promo-codes-transactions-report" component={Transactions} />
                    <CustomRoute exact path="/promo-codes-value-cards" component={PromoCodesValueCards} />

                    <CustomRoute exact path="/articles" component={Articles} />
                    <CustomRoute exact path="/articles/new" component={Article} />
                    <CustomRoute exact path="/articles/:id" component={Article} />

                    <CustomRoute exact path="/custom-fields" component={CustomFields} />
                    <CustomRoute exact path="/custom-fields/new" component={CustomField} />
                    <CustomRoute exact path="/custom-fields/:id" component={CustomField} />

                    <CustomRoute exact path="/setup-account" component={SetupAccount} />
                    <CustomRoute exact path="/setup-account/create-service" component={CreateService} />

                    <CustomRoute exact path="/customers" component={Customers} />
                    <CustomRoute exact path="/customers/inactive" component={InactiveCustomers} />
                    <CustomRoute exact path="/customers/new" component={Customer} />
                    <CustomRoute exact path="/customers/:id" component={Customer} />
                    <CustomRoute exact path="/newsletters" component={Newsletters} />
                    <CustomRoute exact path="/newsletters/send" component={SendNewsletter} />
                    <CustomRoute exact path="/newsletters/log" component={NewsletterLog} />
                    <CustomRoute
                        exact
                        path="/newsletters/templates"
                        component={NewsletterTemplates}
                    />
                    <CustomRoute
                        exact
                        path="/newsletters/new"
                        component={NewsletterTemplate}
                        padded={false}
                    />
                    <CustomRoute
                        exact
                        path="/newsletters/templates/:id"
                        component={NewsletterTemplate}
                        padded={false}
                    />

                    <Redirect exact from="/messages/templates" to="/bookings#message-templates" />
                    <Redirect exact from="/messages/log" to="/bookings#message-log" />
                    <CustomRoute exact path="/bookings" component={BookingsMain} />
                    <CustomRoute exact path="/bookings-new" component={NewBooking} />
                    <CustomRoute exact path="/bookings-report" component={BookingsReport} />
                    <CustomRoute exact path="/bookings/:id" component={EditBooking} />
                    <CustomRoute
                        exact
                        path="/booking-printout"
                        component={BookingPrintoutTemplates}
                    />
                    <CustomRoute
                        exact
                        path="/booking-printout/new"
                        component={BookingPrintoutTemplate}
                        padded={false}
                    />
                    <CustomRoute
                        exact
                        path="/booking-printout/:id"
                        component={BookingPrintoutTemplate}
                        padded={false}
                    />
                    <CustomRoute exact path="/messages/log" component={MessageLog} />
                    <CustomRoute exact path="/messages/templates" component={MessageTemplates} />
                    <CustomRoute
                        exact
                        path="/messages/templates/new"
                        component={MessageTemplate}
                        padded={false}
                    />
                    <CustomRoute
                        exact
                        path="/messages/templates/:id"
                        component={MessageTemplate}
                        padded={false}
                    />
                    <CustomRoute exact path="/booking-settings" component={BookingSettings} />

                    <Redirect exact from="/homepage/images" to="/homepage#images" />
                    <Redirect exact from="/homepage/posts" to="/homepage#posts" />
                    <CustomRoute exact path="/homepage/images" component={HomepageImages} />
                    <CustomRoute exact path="/homepage/images/new" component={HomepageImage} />
                    <CustomRoute exact path="/homepage/images/:id" component={HomepageImage} />
                    <CustomRoute exact path="/homepage/posts" component={HomepagePosts} />
                    <CustomRoute exact path="/homepage/posts/new" component={HomepagePost} />
                    <CustomRoute exact path="/homepage/posts/:id" component={HomepagePost} />
                    <CustomRoute path="/homepage" component={Homepage} />

                    <CustomRoute exact path="/account" component={MySubscription} />
                    <CustomRoute exact path="/extra-licenses" component={ExtraLicenses} />

                    {/* <CustomRoute exact path="/billing/invoices" component={Invoices} /> */}
                    <CustomRoute exact path="/billing/add-credit-card" component={DibsForm} />
                    <CustomRoute exact path="/billing/accept" component={MySubscription} />
                    <CustomRoute exact path="/billing/callback" component={MySubscription} />
                    <CustomRoute
                        exact
                        path="/billing/settings"
                        component={BillingInformationSettings}
                    />
                    <CustomRoute exact path="/reviews" component={Ratings} />
                    <CustomRoute exact path="/system-settings" component={SystemSettings} />
                    <CustomRoute exact path="/mailchimp-settings" component={MailchimpSettings} />
                    <CustomRoute path="/payment-settings" component={PaymentSettings} />
                    <CustomRoute path="/codelock-settings" component={CodelockSettings} />
                    <CustomRoute exact path="/billing/cancel" component={MySubscription} />
                    <CustomRoute exact path="/change-password" component={ChangePassword} />
                    <CustomRoute exact path="/support" component={SupportMethods} />
                    <CustomRoute exact path="/support/manuals" component={SupportManuals} />
                    <CustomRoute exact path="/support/tickets" component={SupportTickets} />
                    <CustomRoute exact path="/support/tickets/new" component={SupportTicket} />
                    <CustomRoute
                        exact
                        path="/support/tickets/:id"
                        component={SupportTicketThread}
                    />
                    <CustomRoute exact path="/support/tickets/:id/edit" component={SupportTicket} />
                    <CustomRoute path="/sync" component={SyncWithCalendar} />
                     <CustomRoute
                        exact
                        path="/eaccounting/articles"
                        component={EaccountingArticles}
                    />
                    
                    <CustomRoute
                        exact
                        path="/eaccounting/settings"
                        component={EASettings}
                    />
                    <Redirect from="/" to="/dashboard" />
                </Switch>
            </MainLayout>
        </ErrorBoundary>
    );
};

export default Full;
