import React, { useCallback, useEffect, useRef } from 'react';
import { Box, Flex } from 'grid-styled';
import { Customer, Price, Service, Time } from '@/types';
import classNames from 'classnames/bind';
import { formattedFromTo, parseIntervalKey, stringifyIntervalKey } from '@/utils/common';
import styles from './NewBookingForm.module.css';
import Form from '@/components/Form';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { calculatePrice, calculatePrices, getTotalQuantityForDate } from '@/store/reducers/calculatePrice';
import { getPricesInfo } from '../components/common';
import { DateQuantityForm } from './DateQuantityForm';
import { ApplyRebateCodes } from './ApplyRebateCodes';
import useTranslate from '@/hooks/useTranslate';
import { Loader } from 'semantic-ui-react';
import { debounce } from 'lodash';


export let cx = classNames.bind(styles);

export const RECALCULATE_PRICE_DEBOUNCE_TIMEOUT = 1000;

export interface DateQuantityProps {
    timeSpot: Time;
    prices: Price[];
    service: Service;
    customer: Customer;
}

interface DateQuantitiesProps {
    timeSpots: Time[];
    prices: Price[];
    service: Service;
    customer: Customer;
}

export const DATE_QUANTITIES_PREFIX = 'DateQuantities_';

export const DateQuantities: React.FC<DateQuantitiesProps> = ({ timeSpots, prices, service, customer }) => {
    const dispatch = useAppDispatch();
    const { hasPrices } = getPricesInfo(service);

    const dateQuantitiesFormValues = useAppSelector(state => {
        return Object.entries(state.form).reduce((acc, curr) => {
            if(curr[0].includes(DATE_QUANTITIES_PREFIX)) {
                const interval = parseIntervalKey(curr[0], DATE_QUANTITIES_PREFIX);
                acc.push([interval, curr[1].values]);
            }
            return acc;
        }, [] as any[])
    });
    const dateQuantityKey = dateQuantitiesFormValues.map((d: any) => `${d[0].From}${d[0].To}${d[1].price}${d[1].quantity}`).join();

    const effect = useCallback((dateQuantitiesFormValues: any[], customerEmail: string) => {
        if(dateQuantitiesFormValues.length === 1) {
            const interval = dateQuantitiesFormValues[0][0];
            const quantities = [{
                Quantity: dateQuantitiesFormValues[0][1].quantity,
                PriceId: dateQuantitiesFormValues[0][1].price
            }];
            
            dispatch(
                calculatePrice({
                    serviceId: service.Id,
                    interval,
                    quantities,
                    customerEmail,
                    rebateCodeIds: [],
                })
            );
        } else if (dateQuantitiesFormValues.length > 1) {
            dispatch(
                calculatePrices(dateQuantitiesFormValues.map((dateQuantity: any) => {
                    const interval = dateQuantity[0];
                    const quantities = [{
                        Quantity: dateQuantity[1].quantity,
                        PriceId: dateQuantity[1].price
                    }];

                    return {
                        serviceId: service.Id,
                        interval,
                        quantities,
                        customerEmail,
                        rebateCodeIds: [],
                    }
                }))
            )

        }
    }, [dateQuantityKey, customer.Email]);

    const debouncedEffect = useRef(debounce(effect, RECALCULATE_PRICE_DEBOUNCE_TIMEOUT));

    const loading = useAppSelector(s => s.calculatePrice.loading);

    useEffect(() => {
        effect(dateQuantitiesFormValues, customer.Email);
    }, [dateQuantityKey]);

    useEffect(() => {
        debouncedEffect.current(dateQuantitiesFormValues, customer.Email);
    }, [customer.Email]);

    return (
        <Box className={cx('DateQuantities')} disabled={loading}>
            {loading ? <Loader /> : null}
            {timeSpots.map((time) => (
                <DateQuantity timeSpot={time} prices={prices} service={service} customer={customer} />
            ))}
            {hasPrices ? <DateQuantitiesSubtotal priceSign={prices[0]?.PriceSign} /> : null}
            {timeSpots.length === 1 && hasPrices ? (
                <ApplyRebateCodes
                    service={service}
                    quantities={dateQuantitiesFormValues
                        // d[0] is timespot, d[1] is quantity
                        .map((d) => d[1])
                        .map((q) => ({ Quantity: q.quantity, PriceId: q.price }))}
                    timeSpot={timeSpots[0]}
                    customer={customer}
                />
            ) : null}
        </Box>
    );
}

export const DateQuantity: React.FC<DateQuantityProps> = ({ timeSpot, prices, service }) => {
    
    const priceInfo = getPricesInfo(service);
    const maxQuantity =
        !priceInfo.hasGroupBooking && !priceInfo.hasMultipleResource ? 1 : timeSpot.FreeSpots;
    
    const totalQuantity = useAppSelector((s) => getTotalQuantityForDate(s, timeSpot.From as string, timeSpot.To as string));

    const onIncrement = (val: number, cb: () => void) => {
        if(totalQuantity < maxQuantity || typeof maxQuantity === 'undefined') {
            cb()
        }
    }

    const onDecrement = (val: number, cb: () => void) => {
        if(val >= 0) {
            cb();
        }
    }

    return !priceInfo.hasPrices || ((!priceInfo.hasGroupBooking && !priceInfo.hasMultipleResource) || maxQuantity === 1) ? (
        <Box className={cx('DateQuantity')}>
            <Box>{formattedFromTo(timeSpot.From, timeSpot.To)}</Box>
            <Form
                key={stringifyIntervalKey(timeSpot, DATE_QUANTITIES_PREFIX)}
                name={stringifyIntervalKey(timeSpot, DATE_QUANTITIES_PREFIX)}
                initialValues={{
                    quantity: maxQuantity === 1 ? 1 : 0,
                    ...(priceInfo.hasPrices ? { price: prices[0].Id } : {}),
                }}
                onSubmit={() => () => {}}
                showActionBar={false}
                showSuccessMesage={false}
            >
                {(formProps: any) => (
                    <DateQuantityForm
                        formProps={formProps}
                        timeSpot={timeSpot}
                        prices={prices}
                        maxQuantity={maxQuantity}
                        service={service}
                        onIncrement={onIncrement}
                        onDecrement={onDecrement}
                        {...formProps}
                    />
                )}
            </Form>
        </Box>
    ) : (
        // each price shown as preselecteed separate dropdown
        <Box className={cx('DateQuantity')}>
            <Box>{formattedFromTo(timeSpot.From, timeSpot.To)}</Box>
            {prices.map((price, index) => {
                return (
                    <Form
                        key={stringifyIntervalKey(
                            timeSpot,
                            `${price.Id}_${DATE_QUANTITIES_PREFIX}`
                        )}
                        name={stringifyIntervalKey(
                            timeSpot,
                            `${price.Id}_${DATE_QUANTITIES_PREFIX}`
                        )}
                        initialValues={{
                            quantity: 0,
                            price: price.Id,
                        }}
                        onSubmit={() => () => {}}
                        showActionBar={false}
                        showSuccessMesage={false}
                    >
                        {(formProps: any) => (
                            <DateQuantityForm
                                formProps={formProps}
                                timeSpot={timeSpot}
                                prices={[price]}
                                maxQuantity={maxQuantity}
                                service={service}
                                onIncrement={onIncrement}
                                onDecrement={onDecrement}
                                {...formProps}
                            />
                        )}
                    </Form>
                );
            })}
        </Box>
    );
};

export const DateQuantitiesSubtotal: React.FC<{ priceSign: string }> = ({ priceSign }) => {
    const calculatedPrice = useAppSelector((state) => state.calculatePrice);
    const { translate } = useTranslate();

    if(!calculatedPrice.entity?.calculatedPrice) {
        return null;
    }

    const appliedCodes = calculatedPrice.entity.appliedCodes?.length === 1 && calculatedPrice.entity.appliedCodes[0].AppliedCodes
        ? calculatedPrice.entity.appliedCodes[0].AppliedCodes
        : [];
    
    const totalPrice = appliedCodes.length === 0
        ? calculatedPrice.entity.calculatedPrice.reduce((acc, curr) => {
            acc = acc + curr.TotalPrice;
            
            return acc;
        }, 0)
        // @ts-ignore
        : calculatedPrice.entity.appliedCodes[0].TotalPrice;

    return (
        <Box className={cx('DateQuantitiesSubtotal')}>
            {calculatedPrice.entity.appliedCodes && appliedCodes.length > 0 ? (
                <Box style={{ display: 'grid', gap: '4px'}}>
                    <Flex className={cx('appliedCode')}>
                        <Box className={cx('sign')}>
                            {translate('newBookingForm.appliedCode', {
                                sign: appliedCodes[0].RebateCodeSign,
                            })}
                        </Box>
                        <Box className={cx('rebatePrice')} color={'var(--positive-text-color)'}>
                            - {appliedCodes[0].RebateAmount}
                            {priceSign}
                        </Box>
                    </Flex>
                
                    <Flex className={cx('total-price')}>
                        <Box>{translate('newBookingForm.prices.total')}</Box>
                        <Box className={cx('right')}>
                            <strong>
                                {totalPrice}
                                {priceSign}
                            </strong>
                        </Box>
                    </Flex>
                </Box>
            ) : (
                <Flex className={cx('total-price')}>
                    <Box>{translate('newBookingForm.prices.total')}</Box>
                    <Box className={cx('right')}>
                        <strong>
                            {totalPrice}
                            {priceSign}
                        </strong>
                    </Box>
                </Flex>
            )}
        </Box>
    );
};

export default DateQuantities;
