import { AnyAction } from 'redux';
import { put } from 'redux-saga/effects';

import * as actions from '@/store/actions';
import { apiClient, throwSubmissionError } from '@/utils';
import { AxiosResponse } from 'axios';
import { SyncmailchimpQuery, SyncMailchimpResponse } from '@/types';
import { PayloadAction } from '@reduxjs/toolkit';

export function* fetchMailchimpSettings(action: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/mailchimp/profile');

        yield put(actions.FETCH_MAILCHIMP_SETTINGS.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_MAILCHIMP_SETTINGS.failure(error));
    }
}

export function* editMailchimpSettings({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.put('/mailchimp/profile', payload);

        yield put(actions.EDIT_MAILCHIMP_SETTINGS.success(response));
    } catch (error) {
        yield put(actions.EDIT_MAILCHIMP_SETTINGS.failure(throwSubmissionError(error)));
    }
}

export function* syncMailchimpUsers({ payload }: PayloadAction<SyncmailchimpQuery>) {
    try {
        const response: AxiosResponse<SyncMailchimpResponse> = yield apiClient.put('/mailchimp/member/company/customer/sync', payload);

        yield put(actions.SYNC_MAILCHIMP_USERS.success(response));
    } catch (error) {
        yield put(actions.SYNC_MAILCHIMP_USERS.failure(throwSubmissionError(error)));
    }
}

