import { AnyAction } from 'redux';
import { takeLatest, fork, all, put, takeEvery, select } from 'redux-saga/effects';
import formActionSaga from 'redux-form-saga';
import qs from 'qs';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { max } from '@/utils/datetime';

import {
    checkThatIsAuthenticated,
    resetPassword,
    changePassword,
    waitForAuthenticateReplace,
    authenticateReLogin,
    keycloakApiLogout,
    postAuthCode,
} from './auth';
import {
    billingSettings,
    editBillingSettings,
    fetchBillingMethods,
    fetchCompanyCreditCard,
    fetchBillingToken,
    fetchInvoices,
    exportInvoice,
    watchBillingSettings,
} from './billing';
import {
    administrators,
    createAdministrator,
    editAdministrator,
    deleteAdministrator,
    administratorRoles,
    deleteUser,
} from './administrators';
import { coordinates } from './coordinates';
import {
    bookingFetchAvailableTimes,
    fetchServiceWithPrice,
    createBooking,
    fetchNextFreeTime,
    updateServiceQuantity,
} from './newBooking';
import {
    bookingPrintoutTemplates,
    createBookingPrintoutTemplate,
    editBookingPrintoutTemplate,
    deleteBookingPrintoutTemplate,
    testBookingPrintoutTemplate,
    bookingPrintoutFieldTranslations,
} from './bookingPrintout';
import {
    bookings,
    bookingDetails,
    bookingLogs,
    editBooking,
    approveBooking,
    declineBooking,
    cancelBooking,
    printReceipt,
    sendReceipt,
    markBookingAsPaid,
    fetchBookingReportOptions,
    bookingStatuses,
    createBookedResource,
    editBookedResource,
    deleteBookedResource,
    editBookedQuantities,
    fetchAvailableResources,
    refundPayment,
    sendPaymentRequest,
    reassignBookedResource,
} from './bookings';
import {
    fetchCompany,
    editCompany,
    fetchCompanyTypes,
    fetchCompanyCategories,
    sendForApproval,
} from './company';
import {
    customFields,
    editCustomField,
    createCustomField,
    deleteCustomField,
    customFieldSlots,
    customFieldValidations,
} from './customFields';
import { currencies } from './currencies';
import { countries } from './countries';
import {
    fetchCustomers,
    searchCustomers,
    sortCustomers,
    createCustomer,
    editCustomer,
    deleteCustomer,
    sendMessageToCustomer,
    fetchGDPR,
    fetchInactiveCustomers,
    deleteInactiveCustomers,
    deleteCustomerAccessKey,
} from './customers';
import {
    customerComments,
    createCustomerComment,
    editCustomerComment,
    deleteCustomerComment,
} from './customerComments';
import {
    homepageImages,
    createHomepageImage,
    editHomepageImage,
    deleteHomepageImage,
} from './homepageImages';
import { homepageMenu, editHomepageMenu } from './homepageMenu';
import {
    homepageSettings,
    editHomepageSettings,
    homepageWidgetSettings,
    editHomepageWidgetSettings,
} from './homepageSettings';
import {
    homepagePosts,
    createHomepagePost,
    editHomepagePost,
    deleteHomepagePost,
} from './homepagePosts';
import { uploadImage } from './images';
import {
    fetchLicenseTypes,
    fetchLicenseInformation,
    fetchCompanyLicenses,
    createLicense,
    cancelLicense,
    cancelAllLicenses,
    checkDomain,
    trials,
    addTrial,
    companyTrials,
    checkTrial,
    fetchLicenseInformationTypes,
} from './licenses';
import { logErrors } from './errors';
import {
    messages,
    dashboardMessages,
    resendMessage,
    messageTemplates,
    createMessageTemplate,
    editMessageTemplate,
    deleteMessageTemplate,
    testMessageTemplate,
    templateTypes,
    messageFieldTranslations,
} from './messages';
import {
    newsletters,
    sendNewsletter,
    resendNewsletter,
    newsletterCustomers,
    newsletterTemplates,
    createNewsletterTemplate,
    editNewsletterTemplate,
    deleteNewsletterTemplate,
    newsletterFieldTranslations,
    newsletterSendMethods,
    newsletterEmailTemplates,
    testNewsletterTemplate,
} from './newsletters';
import {
    services,
    createService,
    editService,
    deleteService,
    copyService,
    scheduleTypes,
    serviceGroups,
    editServicePriceViewType,
    fetchServiceDurationTypes,
    disconnectRecurringShedule,
    disconnectDateShedule,
    disconnectResourceType,
} from './services';
import {
    recurringSchedules,
    recurringSchedulesIntervals,
    dateSchedules,
    createRecurringSchedule,
    editRecurringSchedule,
    deleteRecurringSchedule,
    testRecurringSchedule,
    createDateSchedule,
    editDateSchedule,
    deleteDateSchedule,
    testDateSchedule,
} from './schedules';
import { statistics } from './statistics';
import {
    supportTickets,
    createSupportTicket,
    editSupportTicket,
    deleteSupportTicket,
    supportTicketStatuses,
    supportTicketAreas,
    supportTicketTypes,
    createSupportTicketComment,
    supportTicketComments,
    deleteSupportTicketComment,
    createSupportTicketAttachment,
    supportTicketAttachments,
} from './supportTickets';
import {
    googleCalendarTokens,
    createGoogleCalendarTokens,
    deleteGoogleCalendarTokens,
    iCalTokens,
    createICalTokens,
    deleteICalTokens,
} from './sync';
import {
    resourceTypes,
    createResourceType,
    editResourceType,
    deleteResourceType,
    createResourceTypeWizard,
} from './resourceTypes';
import {
    timeExceptions,
    createTimeException,
    editTimeException,
    deleteTimeException,
    fetchCollidingEvents,
} from './timeExceptions';
import { resources, createResource, editResource, deleteResource } from './resources';
import prices from './prices';
import promoCodes from './promoCodes';
import { fetchBookingSettings, editBookingSettings } from './bookingSettings';
import { fetchSystemSettings, editSystemSettings } from './settings';
import {
    fetchMailchimpSettings,
    editMailchimpSettings,
    syncMailchimpUsers,
} from './mailchimpSettings';
import {
    fetchPaymentSettings,
    editPaymentSettings,
    fetchPaysonAPISettings,
    createPaysonAPISettings,
    editPaysonAPISettings,
    testPaysonAPISettings,
    fetchBillmateAPISettings,
    createBillmateAPISettings,
    editBillmateAPISettings,
    testBillmateAPISettings,
} from './payment';
import { notificationSaga } from './notification';
import { verify } from './verify';

import * as actions from '@/store/actions';
import {
    fetchCodelockSettings,
    editCodelockSettings,
    fetchAxemaSettings,
    fetchVanderbiltSettings,
    fetchParakeySettings,
    editAxemaSettings,
    editVanderbiltSettings,
    editParakeySettings,
    createAxemaSettings,
    createVanderbiltSettings,
    createParakeySettings,
    createCodelockSettings,
    fetchAmidoSettings,
    editAmidoSettings,
    createAmidoSettings,
    fetchTelkeySettings,
    editTelkeySettings,
    createTelkeySettings,
    fetchSiedleSettings,
    editSiedleSettings,
    createSidleSettings,
    fetchRcoM5Settings,
    editRcoM5Settings,
    createRcoM5Settings,
    fetchRcoEnablaSettings,
    createRcoEnablaSettings,
    editRcoEnablaSettings,
    fetchAccessySettings,
    editAccessySettings,
    createAccessySettings,
    fetchZesecSettings,
    editZesecSettings,
    createZesecSettings,
} from './codelock';

import { checkAgreement, acceptAgreement } from './agreements';

import { fetchAccessKeytypes } from './accessKeyTypes';
import { runWootrics } from './analytics';
import { INITIAL_ROUTE_KEY, SHOULD_LOGOUT_STORAGE_KEY } from '@/utils/constants';
import { fetchPriceViewTypes } from './priceViewTypes';
import { fetchCalculationTypes } from './calculationTypes';

// Used for debounsing requests
const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

let firstRoute: string;

// main saga generators
export default function* sagas() {
    yield all([
        // Administrators
        takeLatest(actions.FETCH_ADMINISTRATORS.REQUEST, administrators),
        takeLatest(actions.EDIT_ADMINISTRATOR.REQUEST, editAdministrator),
        takeLatest(actions.CREATE_ADMINISTRATOR.REQUEST, createAdministrator),
        takeLatest(actions.DELETE_ADMINISTRATOR.REQUEST, deleteAdministrator),
        takeLatest(actions.FETCH_ADMINISTRATOR_ROLES.REQUEST, administratorRoles),
        // Auth
        // takeLatest(actions.LOGOUT.REQUEST, logout),
        takeLatest(actions.CHECK_AUTH.REQUEST, checkThatIsAuthenticated),
        takeLatest(actions.RESET_PASSWORD.REQUEST, resetPassword),
        takeLatest(actions.CHANGE_PASSWORD.REQUEST, changePassword),
        takeLatest(actions.KEYCLOAK_API_LOGOUT.REQUEST, keycloakApiLogout),
        // Countries
        takeLatest(actions.FETCH_COUNTRIES.REQUEST, countries),
        // Coordintates
        takeLatest(actions.FETCH_COORDINATES.REQUEST, coordinates),
        // Currencies
        takeLatest(actions.FETCH_CURRENCIES.REQUEST, currencies),
        // Licenses
        takeLatest(actions.FETCH_LICENSE_TYPES.REQUEST, fetchLicenseTypes),
        takeLatest(actions.FETCH_LICENSE_INFO.REQUEST, fetchLicenseInformation),
        takeLatest(actions.FETCH_COMPANY_LICENSES.REQUEST, fetchCompanyLicenses),
        takeLatest(actions.FETCH_LICENSE_INFORMATION_TYPES.REQUEST, fetchLicenseInformationTypes),
        takeLatest(actions.CREATE_COMPANY_LICENSE.REQUEST, createLicense),
        takeLatest(actions.CANCEL_LICENSE.REQUEST, cancelLicense),
        takeLatest(actions.CANCEL_ALL_LICENSES.REQUEST, cancelAllLicenses),
        takeLatest(actions.FETCH_CHECK_DOMAIN.REQUEST, checkDomain),
        takeLatest(actions.FETCH_TRIALS.REQUEST, trials),
        takeLatest(actions.ADD_TRIAL.REQUEST, addTrial),
        takeLatest(actions.FETCH_COMPANY_TRIALS.REQUEST, companyTrials),
        takeLatest(actions.FETCH_CHECK_TRIAL.REQUEST, checkTrial),
        // Company
        takeLatest(actions.FETCH_COMPANY.REQUEST, fetchCompany),
        takeLatest(actions.EDIT_COMPANY.REQUEST, editCompany),
        takeLatest(actions.FETCH_COMPANY_TYPES.REQUEST, fetchCompanyTypes),
        takeLatest(actions.FETCH_COMPANY_CATEGORIES.REQUEST, fetchCompanyCategories),
        takeLatest(actions.SEND_FOR_APPROVAL.REQUEST, sendForApproval),
        // Services
        takeLatest(actions.FETCH_SERVICES.REQUEST, services),
        takeLatest(actions.FETCH_PRICE_VIEW_TYPES.REQUEST, fetchPriceViewTypes),
        takeLatest(actions.FETCH_CALCULATION_TYPES.REQUEST, fetchCalculationTypes),
        takeLatest(actions.EDIT_SERVICE.REQUEST, editService),
        takeLatest(
            actions.DISCONNECT_SERVICE_RECURRING_SCHEDULE.REQUEST,
            disconnectRecurringShedule
        ),
        takeLatest(actions.DISCONNECT_SERVICE_DATE_SCHEDULE.REQUEST, disconnectDateShedule),
        takeLatest(actions.DISCONNECT_SERVICE_RESOURCE_TYPE.REQUEST, disconnectResourceType),
        takeLatest(actions.EDIT_SERVICE_PRICEVIEW_TYPE.REQUEST, editServicePriceViewType),
        takeLatest(actions.CREATE_SERVICE.REQUEST, createService),
        takeLatest(actions.DELETE_SERVICE.REQUEST, deleteService),
        takeLatest(actions.COPY_SERVICE.REQUEST, copyService),
        takeLatest(actions.FETCH_SCHEDULE_TYPES.REQUEST, scheduleTypes),
        takeLatest(actions.FETCH_SERVICE_GROUPS.REQUEST, serviceGroups),
        takeLatest(actions.FETCH_SERVICE_DURATION_TYPES.REQUEST, fetchServiceDurationTypes),
        // Customers
        takeLatest(actions.FETCH_CUSTOMERS.REQUEST, fetchCustomers),
        takeLatest(actions.CREATE_CUSTOMER.REQUEST, createCustomer),
        takeLatest(actions.EDIT_CUSTOMER.REQUEST, editCustomer),
        takeLatest(actions.DELETE_CUSTOMER.REQUEST, deleteCustomer),
        takeLatest(actions.FETCH_CUSTOMER_COMMENTS.REQUEST, customerComments),
        takeLatest(actions.CREATE_CUSTOMER_COMMENT.REQUEST, createCustomerComment),
        takeLatest(actions.EDIT_CUSTOMER_COMMENT.REQUEST, editCustomerComment),
        takeLatest(actions.DELETE_CUSTOMER_COMMENT.REQUEST, deleteCustomerComment),
        takeLatest(actions.SEARCH_CUSTOMERS.REQUEST, searchCustomers),
        takeLatest(actions.SORT_CUSTOMERS, sortCustomers),
        takeLatest(actions.SEND_MESSAGE_TO_CUSTOMER.REQUEST, sendMessageToCustomer),
        takeLatest(actions.FETCH_GDPR.REQUEST, fetchGDPR),
        takeLatest(actions.FETCH_INACTIVE_CUSTOMERS.REQUEST, fetchInactiveCustomers),
        takeLatest(actions.DELETE_INACTIVE_CUSTOMERS.REQUEST, deleteInactiveCustomers),
        // Custom fields
        takeLatest(actions.FETCH_CUSTOM_FIELDS.REQUEST, customFields),
        takeLatest(actions.CREATE_CUSTOM_FIELD.REQUEST, createCustomField),
        takeLatest(actions.EDIT_CUSTOM_FIELD.REQUEST, editCustomField),
        takeLatest(actions.DELETE_CUSTOM_FIELD.REQUEST, deleteCustomField),
        takeLatest(actions.FETCH_CUSTOM_FIELD_SLOTS.REQUEST, customFieldSlots),
        takeLatest(actions.FETCH_CUSTOM_FIELD_VALIDATIONS.REQUEST, customFieldValidations),
        // Billing
        takeLatest(actions.FETCH_BILLING_SETTINGS.REQUEST, billingSettings),
        takeLatest(actions.EDIT_BILLING_SETTINGS.REQUEST, editBillingSettings),
        takeLatest(actions.FETCH_BILLING_METHODS.REQUEST, fetchBillingMethods),
        takeLatest(actions.FETCH_CREDIT_CARD.REQUEST, fetchCompanyCreditCard),
        takeLatest(actions.FETCH_BILLING_TOKEN.REQUEST, fetchBillingToken),
        takeLatest(actions.FETCH_INVOICES.REQUEST, fetchInvoices),
        takeLatest(actions.FETCH_EXPORT_INVOICE.REQUEST, exportInvoice),
        // Home page posts (news)
        takeLatest(actions.FETCH_HOMEPAGE_POSTS.REQUEST, homepagePosts),
        takeLatest(actions.CREATE_HOMEPAGE_POST.REQUEST, createHomepagePost),
        takeLatest(actions.EDIT_HOMEPAGE_POST.REQUEST, editHomepagePost),
        takeLatest(actions.DELETE_HOMEPAGE_POST.REQUEST, deleteHomepagePost),
        // Resource types
        takeLatest(actions.FETCH_RESOURCE_TYPES.REQUEST, resourceTypes),
        takeLatest(actions.CREATE_RESOURCE_TYPE.REQUEST, createResourceType),
        takeLatest(actions.DELETE_RESOURCE_TYPE.REQUEST, deleteResourceType),
        takeLatest(actions.EDIT_RESOURCE_TYPE.REQUEST, editResourceType),
        takeLatest(actions.CREATE_RESOURCE_TYPE_WIZARD.REQUEST, createResourceTypeWizard),
        // Resources
        takeLatest(actions.FETCH_RESOURCES.REQUEST, resources),
        takeLatest(actions.CREATE_RESOURCE.REQUEST, createResource),
        takeLatest(actions.DELETE_RESOURCE.REQUEST, deleteResource),
        takeLatest(actions.EDIT_RESOURCE.REQUEST, editResource),
        // Sync
        takeLatest(actions.FETCH_GOOGLE_CALENDAR_TOKENS.REQUEST, googleCalendarTokens),
        takeLatest(actions.CREATE_GOOGLE_CALENDAR_TOKEN.REQUEST, createGoogleCalendarTokens),
        takeLatest(actions.DELETE_GOOGLE_CALENDAR_TOKEN.REQUEST, deleteGoogleCalendarTokens),
        takeLatest(actions.FETCH_ICAL_TOKENS.REQUEST, iCalTokens),
        takeLatest(actions.CREATE_ICAL_TOKEN.REQUEST, createICalTokens),
        takeLatest(actions.DELETE_ICAL_TOKEN.REQUEST, deleteICalTokens),
        // Time exceptions
        takeLatest(actions.FETCH_TIME_EXCEPTIONS.REQUEST, timeExceptions),
        takeLatest(actions.CREATE_TIME_EXCEPTION.REQUEST, createTimeException),
        takeLatest(actions.DELETE_TIME_EXCEPTION.REQUEST, deleteTimeException),
        takeLatest(actions.EDIT_TIME_EXCEPTION.REQUEST, editTimeException),
        takeLatest(actions.FETCH_COLLIDING_EVENTS.REQUEST, fetchCollidingEvents),
        // Schedules
        takeLatest(actions.FETCH_RECURRING_SCHEDULES.REQUEST, recurringSchedules),
        takeLatest(
            actions.FETCH_RECURRING_SCHEDULES_INTERVALS.REQUEST,
            recurringSchedulesIntervals
        ),
        takeLatest(actions.FETCH_DATE_SCHEDULES.REQUEST, dateSchedules),
        takeLatest(actions.CREATE_RECURRING_SCHEDULE.REQUEST, createRecurringSchedule),
        takeLatest(actions.EDIT_RECURRING_SCHEDULE.REQUEST, editRecurringSchedule),
        takeLatest(actions.DELETE_RECURRING_SCHEDULE.REQUEST, deleteRecurringSchedule),
        takeLatest(actions.FETCH_RECURRING_SCHEDULE_TIMES.REQUEST, testRecurringSchedule),
        takeLatest(actions.CREATE_DATE_SCHEDULE.REQUEST, createDateSchedule),
        takeLatest(actions.EDIT_DATE_SCHEDULE.REQUEST, editDateSchedule),
        takeLatest(actions.DELETE_DATE_SCHEDULE.REQUEST, deleteDateSchedule),
        takeLatest(actions.FETCH_DATE_SCHEDULE_TIMES.REQUEST, testDateSchedule),
        // Homepage images
        takeLatest(actions.FETCH_HOMEPAGE_IMAGES.REQUEST, homepageImages),
        takeLatest(actions.CREATE_HOMEPAGE_IMAGE.REQUEST, createHomepageImage),
        takeLatest(actions.EDIT_HOMEPAGE_IMAGE.REQUEST, editHomepageImage),
        takeLatest(actions.DELETE_HOMEPAGE_IMAGE.REQUEST, deleteHomepageImage),

        // Home page
        takeLatest(actions.FETCH_HOMEPAGE_MENU.REQUEST, homepageMenu),
        takeLatest(actions.EDIT_HOMEPAGE_MENU.REQUEST, editHomepageMenu),
        // Home page settings
        takeLatest(actions.FETCH_HOMEPAGE_SETTINGS.REQUEST, homepageSettings),
        takeLatest(actions.EDIT_HOMEPAGE_SETTINGS.REQUEST, editHomepageSettings),
        // Home page widget settings
        takeLatest(actions.FETCH_HOMEPAGE_WIDGET_SETTINGS.REQUEST, homepageWidgetSettings),
        takeLatest(actions.EDIT_HOMEPAGE_WIDGET_SETTINGS.REQUEST, editHomepageWidgetSettings),
        // Prices
        prices(),
        promoCodes(),
        // Settings
        takeLatest(actions.FETCH_BOOKING_SETTINGS.REQUEST, fetchBookingSettings),
        takeLatest(actions.EDIT_BOOKING_SETTINGS.REQUEST, editBookingSettings),
        takeLatest(actions.FETCH_SYSTEM_SETTINGS.REQUEST, fetchSystemSettings),
        takeLatest(actions.EDIT_SYSTEM_SETTINGS.REQUEST, editSystemSettings),

        takeLatest(actions.FETCH_MAILCHIMP_SETTINGS.REQUEST, fetchMailchimpSettings),
        takeLatest(actions.EDIT_MAILCHIMP_SETTINGS.REQUEST, editMailchimpSettings),
        takeLatest(actions.SYNC_MAILCHIMP_USERS.REQUEST, syncMailchimpUsers),

        takeLatest(actions.FETCH_PAYMENT_SETTINGS.REQUEST, fetchPaymentSettings),
        takeLatest(actions.EDIT_PAYMENT_SETTINGS.REQUEST, editPaymentSettings),
        // Payson
        takeLatest(actions.FETCH_PAYSON_API_SETTINGS.REQUEST, fetchPaysonAPISettings),
        takeLatest(actions.CREATE_PAYSON_API_SETTINGS.REQUEST, createPaysonAPISettings),
        takeLatest(actions.EDIT_PAYSON_API_SETTINGS.REQUEST, editPaysonAPISettings),
        takeLatest(actions.FETCH_TEST_PAYSON_API_SETTINGS.REQUEST, testPaysonAPISettings),
        // Billmate
        takeLatest(actions.FETCH_BILLMATE_API_SETTINGS.REQUEST, fetchBillmateAPISettings),
        takeLatest(actions.CREATE_BILLMATE_API_SETTINGS.REQUEST, createBillmateAPISettings),
        takeLatest(actions.EDIT_BILLMATE_API_SETTINGS.REQUEST, editBillmateAPISettings),
        takeLatest(actions.FETCH_TEST_BILLMATE_API_SETTINGS.REQUEST, testBillmateAPISettings),
        // Support tickets
        takeLatest(actions.FETCH_SUPPORT_TICKETS.REQUEST, supportTickets),
        takeLatest(actions.CREATE_SUPPORT_TICKET.REQUEST, createSupportTicket),
        takeLatest(actions.EDIT_SUPPORT_TICKET.REQUEST, editSupportTicket),
        takeLatest(actions.DELETE_SUPPORT_TICKET.REQUEST, deleteSupportTicket),
        takeLatest(actions.FETCH_SUPPORT_TICKET_STATUSES.REQUEST, supportTicketStatuses),
        takeLatest(actions.FETCH_SUPPORT_TICKET_AREAS.REQUEST, supportTicketAreas),
        takeLatest(actions.FETCH_SUPPORT_TICKET_TYPES.REQUEST, supportTicketTypes),
        takeLatest(actions.FETCH_SUPPORT_TICKET_COMMENTS.REQUEST, supportTicketComments),
        takeLatest(actions.CREATE_SUPPORT_TICKET_COMMENT.REQUEST, createSupportTicketComment),
        takeLatest(actions.DELETE_SUPPORT_TICKET_COMMENT.REQUEST, deleteSupportTicketComment),
        takeLatest(actions.FETCH_SUPPORT_TICKET_ATTACHMENTS.REQUEST, supportTicketAttachments),

        takeLatest(actions.CREATE_SUPPORT_TICKET_ATTACHMENT.REQUEST, createSupportTicketAttachment),
        // Statistics
        takeLatest(actions.FETCH_STATISTICS.REQUEST, statistics),

        // Create booking
        takeLatest(actions.FETCH_SERVICE_WITH_PRICE.REQUEST, fetchServiceWithPrice),
        takeLatest(actions.CREATE_BOOKING.REQUEST, createBooking),
        takeLatest(actions.FETCH_AVAILABLE_TIMES.REQUEST, bookingFetchAvailableTimes),
        takeLatest(actions.FETCH_NEXT_FREE_TIME.REQUEST, fetchNextFreeTime),
        // Bookings
        takeLatest(actions.FETCH_BOOKINGS.REQUEST, bookings),
        takeLatest(actions.FETCH_BOOKING_DETAILS.REQUEST, bookingDetails),
        takeLatest(actions.EDIT_BOOKING.REQUEST, editBooking),
        takeLatest(actions.APPROVE_BOOKING.REQUEST, approveBooking),
        takeLatest(actions.DECLINE_BOOKING.REQUEST, declineBooking),
        takeLatest(actions.CANCEL_BOOKING.REQUEST, cancelBooking),
        takeLatest(actions.FETCH_BOOKING_LOGS.REQUEST, bookingLogs),
        takeLatest(actions.PRINT_RECEIPT.REQUEST, printReceipt),
        takeLatest(actions.SEND_RECEIPT.REQUEST, sendReceipt),
        takeLatest(actions.MARK_BOOKING_AS_PAID.REQUEST, markBookingAsPaid),
        takeLatest(actions.FETCH_BOOKING_REPORT_OPTIONS.REQUEST, fetchBookingReportOptions),
        takeLatest(actions.FETCH_BOOKING_STATUSES.REQUEST, bookingStatuses),
        takeLatest(actions.CREATE_BOOKED_RESOURCE.REQUEST, createBookedResource),
        takeLatest(actions.EDIT_BOOKED_RESOURCE.REQUEST, editBookedResource),
        takeLatest(actions.DELETE_BOOKED_RESOURCE.REQUEST, deleteBookedResource),
        takeLatest(actions.EDIT_BOOKED_QUANTITIES.REQUEST, editBookedQuantities),
        takeLatest(actions.FETCH_AVAILABLE_RESOURCES.REQUEST, fetchAvailableResources),
        takeLatest(actions.REFUND_PAYMENT.REQUEST, refundPayment),
        takeLatest(actions.SEND_PAYMENT_REQUEST.REQUEST, sendPaymentRequest),
        takeLatest(actions.REASSIGN_BOOKED_RESOURCE.REQUEST, reassignBookedResource),
        // Messages
        takeLatest(actions.FETCH_MESSAGE_LOG.REQUEST, messages),
        takeLatest(actions.RESEND_MESSAGE.REQUEST, resendMessage),
        takeLatest(actions.FETCH_MESSAGE_DASHBOARD.REQUEST, dashboardMessages),
        takeLatest(actions.FETCH_MESSAGE_TEMPLATES.REQUEST, messageTemplates),
        takeLatest(actions.CREATE_MESSAGE_TEMPLATE.REQUEST, createMessageTemplate),
        takeLatest(actions.EDIT_MESSAGE_TEMPLATE.REQUEST, editMessageTemplate),
        takeLatest(actions.DELETE_MESSAGE_TEMPLATE.REQUEST, deleteMessageTemplate),
        takeLatest(actions.TEST_MESSAGE_TEMPLATE.REQUEST, testMessageTemplate),
        takeLatest(actions.FETCH_MESSAGE_TEMPLATE_TYPES.REQUEST, templateTypes),
        takeLatest(actions.FETCH_MESSAGE_FIELD_TRANSLATIONS.REQUEST, messageFieldTranslations),
        // Newsletters
        takeLatest(actions.FETCH_NEWSLETTER_LOG.REQUEST, newsletters),
        takeLatest(actions.SEND_NEWSLETTER.REQUEST, sendNewsletter),
        takeLatest(actions.RESEND_NEWSLETTER.REQUEST, resendNewsletter),
        takeLatest(actions.FETCH_NEWSLETTER_CUSTOMERS.REQUEST, newsletterCustomers),
        takeLatest(actions.FETCH_NEWSLETTER_TEMPLATES.REQUEST, newsletterTemplates),
        takeLatest(actions.CREATE_NEWSLETTER_TEMPLATE.REQUEST, createNewsletterTemplate),
        takeLatest(actions.EDIT_NEWSLETTER_TEMPLATE.REQUEST, editNewsletterTemplate),
        takeLatest(actions.DELETE_NEWSLETTER_TEMPLATE.REQUEST, deleteNewsletterTemplate),

        takeLatest(
            actions.FETCH_NEWSLETTER_FIELD_TRANSLATIONS.REQUEST,
            newsletterFieldTranslations
        ),
        takeLatest(actions.FETCH_NEWSLETTER_SEND_METHODS.REQUEST, newsletterSendMethods),
        takeLatest(actions.FETCH_NEWSLETTER_EMAIL_TEMPLATES.REQUEST, newsletterEmailTemplates),
        takeLatest(actions.TEST_NEWSLETTER_TEMPLATE.REQUEST, testNewsletterTemplate),
        // Booking printout
        takeLatest(actions.FETCH_BOOKING_PRINTOUT_TEMPLATES.REQUEST, bookingPrintoutTemplates),

        takeLatest(actions.CREATE_BOOKING_PRINTOUT_TEMPLATE.REQUEST, createBookingPrintoutTemplate),
        takeLatest(actions.EDIT_BOOKING_PRINTOUT_TEMPLATE.REQUEST, editBookingPrintoutTemplate),

        takeLatest(actions.DELETE_BOOKING_PRINTOUT_TEMPLATE.REQUEST, deleteBookingPrintoutTemplate),
        takeLatest(actions.TEST_BOOKING_PRINTOUT_TEMPLATE.REQUEST, testBookingPrintoutTemplate),

        takeLatest(
            actions.FETCH_BOOKING_PRINTOUT_FIELD_TRANSLATIONS.REQUEST,
            bookingPrintoutFieldTranslations
        ),
        // Images
        takeLatest(actions.UPLOAD_IMAGE.REQUEST, uploadImage),
        // Errors
        takeLatest(actions.LOG_ERRORS.REQUEST, logErrors),
        // Verification
        takeLatest(actions.FETCH_VERIFY_EMAIL.REQUEST, verify),

        // Codelock
        takeLatest(actions.FETCH_CODELOCK_SETTINGS.REQUEST, fetchCodelockSettings),
        takeLatest(actions.EDIT_CODELOCK_SETTINGS.REQUEST, editCodelockSettings),
        takeLatest(actions.CREATE_CODELOCK_SETTINGS.REQUEST, createCodelockSettings),
        takeLatest(actions.FETCH_AXEMA_SETTINGS.REQUEST, fetchAxemaSettings),
        takeLatest(actions.FETCH_SIEDLE_SETTINGS.REQUEST, fetchSiedleSettings),
        takeLatest(actions.EDIT_SIEDLE_SETTINGS.REQUEST, editSiedleSettings),
        takeLatest(actions.CREATE_SIEDLE_SETTINGS.REQUEST, createSidleSettings),
        takeLatest(actions.EDIT_AXEMA_SETTINGS.REQUEST, editAxemaSettings),
        takeLatest(actions.CREATE_AXEMA_SETTINGS.REQUEST, createAxemaSettings),
        takeLatest(actions.FETCH_ACCESSY_SETTINGS.REQUEST, fetchAccessySettings),
        takeLatest(actions.EDIT_ACCESSY_SETTINGS.REQUEST, editAccessySettings),
        takeLatest(actions.CREATE_ACCESSY_SETTINGS.REQUEST, createAccessySettings),
        takeLatest(actions.FETCH_VANDERBILT_SETTINGS.REQUEST, fetchVanderbiltSettings),
        takeLatest(actions.EDIT_VANDERBILT_SETTINGS.REQUEST, editVanderbiltSettings),
        takeLatest(actions.CREATE_VANDERBILT_SETTINGS.REQUEST, createVanderbiltSettings),
        takeLatest(actions.FETCH_TELKEY_SETTINGS.REQUEST, fetchTelkeySettings),
        takeLatest(actions.EDIT_TELKEY_SETTINGS.REQUEST, editTelkeySettings),
        takeLatest(actions.CREATE_TELKEY_SETTINGS.REQUEST, createTelkeySettings),
        takeLatest(actions.FETCH_AMIDO_SETTINGS.REQUEST, fetchAmidoSettings),
        takeLatest(actions.EDIT_AMIDO_SETTINGS.REQUEST, editAmidoSettings),
        takeLatest(actions.CREATE_AMIDO_SETTINGS.REQUEST, createAmidoSettings),
        takeLatest(actions.FETCH_PARAKEY_SETTINGS.REQUEST, fetchParakeySettings),
        takeLatest(actions.EDIT_PARAKEY_SETTINGS.REQUEST, editParakeySettings),
        takeLatest(actions.CREATE_PARAKEY_SETTINGS.REQUEST, createParakeySettings),
        takeLatest(actions.FETCH_RCO_M5_SETTINGS.REQUEST, fetchRcoM5Settings),
        takeLatest(actions.EDIT_RCO_M5_SETTINGS.REQUEST, editRcoM5Settings),
        takeLatest(actions.CREATE_RCO_M5_SETTINGS.REQUEST, createRcoM5Settings),

        takeLatest(actions.FETCH_RCO_ENABLA_SETTINGS.REQUEST, fetchRcoEnablaSettings),
        takeLatest(actions.EDIT_RCO_ENABLA_SETTINGS.REQUEST, editRcoEnablaSettings),
        takeLatest(actions.CREATE_RCO_ENABLA_SETTINGS.REQUEST, createRcoEnablaSettings),

        takeLatest(actions.FETCH_ZESEC_SETTINGS.REQUEST, fetchZesecSettings),
        takeLatest(actions.EDIT_ZESEC_SETTINGS.REQUEST, editZesecSettings),
        takeLatest(actions.CREATE_ZESEC_SETTINGS.REQUEST, createZesecSettings),

        takeLatest(actions.WAIT_FOR_AUTHENTICATE_REPLACE.REQUEST, waitForAuthenticateReplace),
        takeLatest(actions.AUTHENTICATE_RE_LOGIN.REQUEST, authenticateReLogin),

        takeLatest(actions.DELETE_USER.REQUEST, deleteUser),
        takeLatest(actions.FETCH_ACCESSKEYTYPES.REQUEST, fetchAccessKeytypes),
        takeLatest(actions.DELETE_CUSTOMER_ACCESS_KEY.REQUEST, deleteCustomerAccessKey),
        takeLatest(actions.CHECK_AUTH.SUCCESS, checkAgreement),
        takeLatest(actions.ACCEPT_AGREEMENT, acceptAgreement),
        takeLatest(actions.FETCH_COMPANY.SUCCESS, runWootrics),

        takeLatest(actions.EACCOUNTING_POST_AUTH_CODE.REQUEST, postAuthCode),
        takeLatest(actions.UPDATE_SERVICE_QUANTITY.REQUEST, updateServiceQuantity),

        takeLatest(actions.AUTHENTICATE.SUCCESS, function* ({ payload }: AnyAction) {
            const code = sessionStorage.getItem('eaccountingCode');
            const companyId = payload.data.Meta.CompanyId;

            if (code) {
                yield put(actions.EACCOUNTING_POST_AUTH_CODE.request({ code, companyId }));
            }
        }),

        // Redux-form related sagas

        takeLatest(
            ['@@redux-form/CHANGE', '@@redux-form/ARRAY_PUSH', '@@redux-form/ARRAY_REMOVE'],
            function* ({ type, meta: { form, field }, payload }: AnyAction) {
                if (form === 'customerFilters') {
                    // Debounce search
                    yield delay(500);
                    yield put(actions.FETCH_CUSTOMERS.request({ IncludeComments: true }));
                }

                if (form.startsWith('customField')) {
                    if (field === 'Table') {
                        yield put(
                            actions.FETCH_CUSTOM_FIELD_SLOTS.request({
                                Table: payload,
                            })
                        );
                    }
                }

                // Recurring schedule preview
                if (form.startsWith('recurringSchedule')) {
                    const fieldPatterns = [
                        /type/,
                        /DaysOfWeek/,
                        /StartTime/,
                        /EndTime/,
                        /ValidFrom/,
                        /ValidTo/,
                        /EnableBookingUntilClosingTime/,
                        /TimeInterval/,
                        /NumberOfScheduleDays/,
                        /Exceptions/,
                        /groupedScheduleDates/,
                        /allDayAndNight/,
                    ];

                    if (fieldPatterns.some((pattern) => pattern.test(field))) {
                        if (type === '@@redux-form/ARRAY_REMOVE') {
                            yield delay(500);
                        }

                        const { navigationDate } = yield select(
                            (state) => state.schedulePreview.ui
                        );
                        yield put(
                            actions.FETCH_RECURRING_SCHEDULE_TIMES.request({
                                navigationDate,
                            })
                        );
                    }
                }
                // Date schedule preview
                if (form.startsWith('dateSchedule')) {
                    const fieldPatterns = [
                        /ValidFrom/,
                        /ValidTo/,
                        /EnableBookingUntilClosingTime/,
                        /TimeInterval/,
                        /NumberOfScheduleDays/,
                        /Exceptions/,
                        /ScheduleDates/,
                    ];

                    if (fieldPatterns.some((pattern) => pattern.test(field))) {
                        if (type === '@@redux-form/ARRAY_REMOVE') {
                            yield delay(500);
                        }

                        const { navigationDate } = yield select(
                            (state) => state.schedulePreview.ui
                        );
                        yield put(actions.FETCH_DATE_SCHEDULE_TIMES.request({ navigationDate }));
                    }
                }
            }
        ),

        takeLatest('@@redux-form/INITIALIZE', function* ({ meta: { form }, payload }: AnyAction) {
            if (form.startsWith('customField')) {
                const { Table } = payload;
                if (Table) {
                    yield put(actions.FETCH_CUSTOM_FIELD_SLOTS.request({ Table }));
                }
            }
            // On schedules form initialization set preview date to ValidFrom
            if (form === 'recurringSchedule' || form === 'dateSchedule') {
                // Don't show past dates
                const navigationDate = payload.ValidFrom
                    ? max([payload.ValidFrom, new Date()])
                    : new Date();
                yield put({
                    type: actions.CHANGE_SCHEDULE_PREVIEW_NAVIGATION_DATE,
                    payload: navigationDate,
                });
                yield put(
                    actions.FETCH_RECURRING_SCHEDULE_TIMES.request({
                        navigationDate,
                    })
                );
            }
        }),

        takeLatest(LOCATION_CHANGE, function* ({ payload }: AnyAction) {
            const actionLocation = payload.location;
            const query = qs.parse(actionLocation.search, { ignoreQueryPrefix: true });

            const isAccount = !!(query.Account || query.account);

            if (isAccount) {
                sessionStorage.setItem(SHOULD_LOGOUT_STORAGE_KEY, 'true');
            }

            if (!actionLocation.pathname.includes('/auth') && !isAccount) {
                const searchParams = new URLSearchParams(payload.location.search);
                const activeTab = payload.location.hash.split('#')[1];
                if (activeTab) {
                    searchParams.append('activeTab', activeTab);
                }
                const initialRoute = `${payload.location.pathname}?${searchParams.toString()}`;
                sessionStorage.setItem(INITIAL_ROUTE_KEY, initialRoute);
            }

            yield;
        }),

        takeEvery(actions.HIDE_LICENSES_MODAL, function* () {
            yield put(push('/dashboard'));
        }),

        fork(formActionSaga),
        takeEvery('*', notificationSaga),
        fork(watchBillingSettings),
    ]);
}
