import { createFormAction } from 'redux-form-saga';
import { createAction, PayloadAction } from '@reduxjs/toolkit'
import { CreateInvoiceResponse, CreateInvoiceType, RebateCode, ServicePriceMapping, TotalPriceInformationResponse } from '@/types';
import { convertErrorToFormError } from '@/utils';
import { AxiosError } from 'axios';
import { definitions } from '@/apitypes';

// Common
export const TOGGLE_SIDEBAR_OPEN = 'TOGGLE_SIDEBAR_OPEN';

// Booking settings
export const FETCH_BOOKING_SETTINGS = createFormAction('FETCH_BOOKING_SETTINGS');
export const EDIT_BOOKING_SETTINGS = createFormAction('EDIT_BOOKING_SETTINGS');
export const UPDATE_BOOKING_TIME = 'UPDATE_BOOKING_TIME';
// Homepage
export const FETCH_HOMEPAGE_IMAGES = createFormAction('FETCH_HOMEPAGE_IMAGES');
export const CREATE_HOMEPAGE_IMAGE = createFormAction('CREATE_HOMEPAGE_IMAGE');
export const EDIT_HOMEPAGE_IMAGE = createFormAction('EDIT_HOMEPAGE_IMAGE');
export const DELETE_HOMEPAGE_IMAGE = createFormAction('DELETE_HOMEPAGE_IMAGE');
export const FETCH_HOMEPAGE_MENU = createFormAction('FETCH_HOMEPAGE_MENU');
export const EDIT_HOMEPAGE_MENU = createFormAction('EDIT_HOMEPAGE_MENU');
export const FETCH_HOMEPAGE_SETTINGS = createFormAction('FETCH_HOMEPAGE_SETTINGS');
export const EDIT_HOMEPAGE_SETTINGS = createFormAction('EDIT_HOMEPAGE_SETTINGS');
export const FETCH_HOMEPAGE_WIDGET_SETTINGS = createFormAction('FETCH_HOMEPAGE_WIDGET_SETTINGS');
export const EDIT_HOMEPAGE_WIDGET_SETTINGS = createFormAction('EDIT_HOMEPAGE_WIDGET_SETTINGS');
// Form actions
export const AUTHENTICATE = createFormAction('AUTHENTICATE');
export const RESET_PASSWORD = createFormAction('RESET_PASSWORD');
export const CHANGE_PASSWORD = createFormAction('CHANGE_PASSWORD');
export const LOGOUT = createFormAction('LOGOUT');
export const CREATE_BOOKING = createFormAction('CREATE_BOOKING');
export const ADD_TO_QUEUE_SUCCESS = 'ADD_TO_QUEUE_SUCCESS';

// Company
export const FETCH_COMPANY = createFormAction('FETCH_COMPANY');
export const EDIT_COMPANY = createFormAction('EDIT_COMPANY');
export const FETCH_COMPANY_TYPES = createFormAction('FETCH_COMPANY_TYPES');
export const FETCH_COMPANY_CATEGORIES = createFormAction('FETCH_COMPANY_CATEGORIES');
export const SEND_FOR_APPROVAL = createFormAction('SEND_FOR_APPROVAL');
// System settings
export const FETCH_SYSTEM_SETTINGS = createFormAction('FETCH_SYSTEM_SETTINGS');
export const EDIT_SYSTEM_SETTINGS = createFormAction('EDIT_SYSTEM_SETTINGS');

// Mailchimp settings
export const FETCH_MAILCHIMP_SETTINGS = createFormAction('FETCH_MAILCHIMP_SETTINGS');
export const EDIT_MAILCHIMP_SETTINGS = createFormAction('EDIT_MAILCHIMP_SETTINGS');
export const SYNC_MAILCHIMP_USERS = createFormAction('SYNC_MAILCHIMP_USERS');

// Payment settings
export const FETCH_PAYMENT_SETTINGS = createFormAction('FETCH_PAYMENT_SETTINGS');
export const EDIT_PAYMENT_SETTINGS = createFormAction('EDIT_PAYMENT_SETTINGS');
// Payson settings
export const FETCH_PAYSON_API_SETTINGS = createFormAction('FETCH_PAYSON_API_SETTINGS');
export const CREATE_PAYSON_API_SETTINGS = createFormAction('CREATE_PAYSON_API_SETTINGS');
export const EDIT_PAYSON_API_SETTINGS = createFormAction('EDIT_PAYSON_API_SETTINGS');
export const FETCH_TEST_PAYSON_API_SETTINGS = createFormAction('FETCH_TEST_PAYSON_API_SETTINGS');
// Billmate settings
export const FETCH_BILLMATE_API_SETTINGS = createFormAction('FETCH_BILLMATE_API_SETTINGS');
export const CREATE_BILLMATE_API_SETTINGS = createFormAction('CREATE_BILLMATE_API_SETTINGS');
export const EDIT_BILLMATE_API_SETTINGS = createFormAction('EDIT_BILLMATE_API_SETTINGS');
export const FETCH_TEST_BILLMATE_API_SETTINGS = createFormAction(
    'FETCH_TEST_BILLMATE_API_SETTINGS'
);

export const CHECK_AUTH = createFormAction('CHECK_AUTH');
// Administrators
export const FETCH_ADMINISTRATORS = createFormAction('FETCH_ADMINISTRATORS');
export const EDIT_ADMINISTRATOR = createFormAction('EDIT_ADMINISTRATOR');
export const CREATE_ADMINISTRATOR = createFormAction('CREATE_ADMINISTRATOR');
export const DELETE_ADMINISTRATOR = createFormAction('DELETE_ADMINISTRATOR');
export const FETCH_ADMINISTRATOR_ROLES = createFormAction('FETCH_ADMINISTRATOR_ROLES');
// Billing
export const FETCH_BILLING_SETTINGS = createFormAction('FETCH_BILLING_SETTINGS');
export const EDIT_BILLING_SETTINGS = createFormAction('EDIT_BILLING_SETTINGS');
export const FETCH_BILLING_METHODS = createFormAction('FETCH_BILLING_METHODS');
export const FETCH_CREDIT_CARD = createFormAction('FETCH_CREDIT_CARD');
export const ADD_CREDIT_CARD = createFormAction('ADD_CREDIT_CARD');
export const FETCH_BILLING_TOKEN = createFormAction('FETCH_BILLING_TOKEN');
export const FETCH_INVOICES = createFormAction('FETCH_INVOICES');
export const FETCH_EXPORT_INVOICE = createFormAction('FETCH_EXPORT_INVOICE');
export const SHOW_BILLING_INFO_MODAL = 'SHOW_BILLING_INFO_MODAL';
export const HIDE_BILLING_INFO_MODAL = 'HIDE_BILLING_INFO_MODAL';
// countries
export const FETCH_COUNTRIES = createFormAction('FETCH_COUNTRIES');
// countries
export const FETCH_COORDINATES = createFormAction('FETCH_COORDINATES');
// currencies
export const FETCH_CURRENCIES = createFormAction('FETCH_CURRENCIES');
// Licenses
export const SHOW_LICENSES_MODAL = 'SHOW_LICENSES_MODAL';
export const HIDE_LICENSES_MODAL = 'HIDE_LICENSES_MODAL';
export const FETCH_LICENSE_INFO = createFormAction('FETCH_LICENSE_INFO');
export const FETCH_LICENSE_TYPES = createFormAction('FETCH_LICENSE_TYPES');
export const FETCH_COMPANY_LICENSES = createFormAction('FETCH_COMPANY_LICENSES');
export const CREATE_COMPANY_LICENSE = createFormAction('CREATE_COMPANY_LICENSE');
export const CANCEL_LICENSE = createFormAction('CANCEL_LICENSE');
export const CANCEL_ALL_LICENSES = createFormAction('CANCEL_ALL_LICENSES');
export const FETCH_CHECK_DOMAIN = createFormAction('FETCH_CHECK_DOMAIN');
export const FETCH_TRIALS = createFormAction('FETCH_TRIALS');
export const ADD_TRIAL = createFormAction('ADD_TRIAL');
export const FETCH_COMPANY_TRIALS = createFormAction('FETCH_COMPANY_TRIALS');
export const FETCH_CHECK_TRIAL = createFormAction('FETCH_CHECK_TRIAL');
export const FETCH_LICENSE_INFORMATION_TYPES = createFormAction('FETCH_LICENSE_INFORMATION_TYPES');
// Customers
export const FETCH_CUSTOMERS = createFormAction('FETCH_CUSTOMERS');
export const CREATE_CUSTOMER = createFormAction('CREATE_CUSTOMER');
export const EDIT_CUSTOMER = createFormAction('EDIT_CUSTOMER');
export const DELETE_CUSTOMER = createFormAction('DELETE_CUSTOMER');
export const FETCH_CUSTOMER_COMMENTS = createFormAction('FETCH_CUSTOMER_COMMENTS');
export const CREATE_CUSTOMER_COMMENT = createFormAction('CREATE_CUSTOMER_COMMENT');
export const EDIT_CUSTOMER_COMMENT = createFormAction('EDIT_CUSTOMER_COMMENT');
export const DELETE_CUSTOMER_COMMENT = createFormAction('DELETE_CUSTOMER_COMMENT');
export const SORT_CUSTOMERS = 'SORT_CUSTOMERS';
export const FETCH_GDPR = createFormAction('FETCH_GDPR');
export const FETCH_INACTIVE_CUSTOMERS = createFormAction('FETCH_INACTIVE_CUSTOMERS');
export const DELETE_INACTIVE_CUSTOMERS = createFormAction('DELETE_INACTIVE_CUSTOMERS');
export const DELETE_INACTIVE_CUSTOMERS_IN_PROGRESS = 'DELETE_INACTIVE_CUSTOMERS_IN_PROGRESS';
// Custom fields
export const FETCH_CUSTOM_FIELDS = createFormAction('FETCH_CUSTOM_FIELDS');
export const CREATE_CUSTOM_FIELD = createFormAction('CREATE_CUSTOM_FIELD');
export const EDIT_CUSTOM_FIELD = createFormAction('EDIT_CUSTOM_FIELD');
export const DELETE_CUSTOM_FIELD = createFormAction('DELETE_CUSTOM_FIELD');
export const FETCH_CUSTOM_FIELD_SLOTS = createFormAction('FETCH_CUSTOM_FIELD_SLOTS');
export const FETCH_CUSTOM_FIELD_VALIDATIONS = createFormAction('FETCH_CUSTOM_FIELD_VALIDATIONS');
// Prices
export const FETCH_PRICES = createFormAction('FETCH_PRICES');
export const EDIT_PRICE = createFormAction('EDIT_PRICE');
export const CREATE_PRICE = createFormAction('CREATE_PRICE');
export const DELETE_PRICE = createFormAction('DELETE_PRICE');
// Prices
export const FETCH_PROMO_CODES = createFormAction('FETCH_PROMO_CODES');
export const EDIT_PROMO_CODE = createFormAction('EDIT_PROMO_CODE');
export const CREATE_PROMO_CODE = createFormAction('CREATE_PROMO_CODE');
export const DELETE_PROMO_CODE = createFormAction('DELETE_PROMO_CODE');
export const EXPORT_PROMO_CODES = createFormAction('EXPORT_PROMO_CODES');
export const FETCH_PROMO_CODES_TRANSACTIONS = createFormAction('FETCH_PROMO_CODES_TRANSACTIONS');
// ResourceTypes
export const FETCH_RESOURCE_TYPES = createFormAction('FETCH_RESOURCE_TYPES');
export const EDIT_RESOURCE_TYPE = createFormAction('EDIT_RESOURCE_TYPE');
export const CREATE_RESOURCE_TYPE = createFormAction('CREATE_RESOURCE_TYPE');
export const DELETE_RESOURCE_TYPE = createFormAction('DELETE_RESOURCE_TYPE');
export const SHOW_NEW_RESOURCE_MODAL = 'SHOW_NEW_RESOURCE_MODAL';

// Resources
export const FETCH_RESOURCES = createFormAction('FETCH_RESOURCES');
export const EDIT_RESOURCE = createFormAction('EDIT_RESOURCE');
export const CREATE_RESOURCE = createFormAction('CREATE_RESOURCE');
export const DELETE_RESOURCE = createFormAction('DELETE_RESOURCE');
export const RESOURCE_BOOKINGS_CONFLICT = 'RESOURCE_BOOKINGS_CONFLICT';
export const CLOSE_RESOURCE_BOOKINGS_CONFLICT_MODAL = 'CLOSE_RESOURCE_BOOKINGS_CONFLICT_MODAL';
export const FILTER_RESOURCES = 'FILTER_RESOURCES';
// Schedules
export const FETCH_RECURRING_SCHEDULES = createFormAction('FETCH_RECURRING_SCHEDULES');
export const FETCH_RECURRING_SCHEDULES_INTERVALS = createFormAction(
    'FETCH_RECURRING_SCHEDULES_INTERVALS'
);

export const FETCH_DATE_SCHEDULES = createFormAction('FETCH_DATE_SCHEDULES');
export const EDIT_RECURRING_SCHEDULE = createFormAction('EDIT_RECURRING_SCHEDULE');
export const CREATE_RECURRING_SCHEDULE = createFormAction('CREATE_RECURRING_SCHEDULE');
export const DELETE_RECURRING_SCHEDULE = createFormAction('DELETE_RECURRING_SCHEDULE');
export const FETCH_RECURRING_SCHEDULE_TIMES = createFormAction('FETCH_RECURRING_SCHEDULE_TIMES');
export const EDIT_DATE_SCHEDULE = createFormAction('EDIT_DATE_SCHEDULE');
export const CREATE_DATE_SCHEDULE = createFormAction('CREATE_DATE_SCHEDULE');
export const DELETE_DATE_SCHEDULE = createFormAction('DELETE_DATE_SCHEDULE');
export const FETCH_DATE_SCHEDULE_TIMES = createFormAction('FETCH_DATE_SCHEDULE_TIMES');
export const RESET_SCHEDULE_PREVIEW = 'RESET_SCHEDULE_PREVIEW';
export const CHANGE_SCHEDULE_PREVIEW_NAVIGATION_DATE = 'CHANGE_SCHEDULE_PREVIEW_NAVIGATION_DATE';
export const CHANGE_SCHEDULE_PREVIEW_DURATION = 'CHANGE_SCHEDULE_PREVIEW_DURATION';
// Time exceptions
export const FETCH_TIME_EXCEPTIONS = createFormAction('FETCH_TIME_EXCEPTIONS');
export const EXPORT_TIME_EXCEPTIONS = createFormAction('EXPORT_TIME_EXCEPTIONS');
export const EDIT_TIME_EXCEPTION = createFormAction('EDIT_TIME_EXCEPTION');
export const CREATE_TIME_EXCEPTION = createFormAction('CREATE_TIME_EXCEPTION');
export const DELETE_TIME_EXCEPTION = createFormAction('DELETE_TIME_EXCEPTION');
// Time exceptions
export const FETCH_HOMEPAGE_POSTS = createFormAction('FETCH_HOMEPAGE_POSTS');
export const EDIT_HOMEPAGE_POST = createFormAction('EDIT_HOMEPAGE_POST');
export const CREATE_HOMEPAGE_POST = createFormAction('CREATE_HOMEPAGE_POST');
export const DELETE_HOMEPAGE_POST = createFormAction('DELETE_HOMEPAGE_POST');
export const TIME_EXCEPTION_BOOKINGS_CONFLICT = 'TIME_EXCEPTION_BOOKINGS_CONFLICT';
export const CLOSE_TIME_EXCEPTION_BOOKINGS_CONFLICT_MODAL =
    'CLOSE_TIME_EXCEPTION_BOOKINGS_CONFLICT_MODAL';
// Services
export const FETCH_SERVICES = createFormAction('FETCH_SERVICES');
export const EDIT_SERVICE = createFormAction('EDIT_SERVICE');
export const DISCONNECT_SERVICE_RECURRING_SCHEDULE = createFormAction('DISCONNECT_SERVICE_RECURRING_SCHEDULE');
export const DISCONNECT_SERVICE_DATE_SCHEDULE = createFormAction('DISCONNECT_SERVICE_DATE_SCHEDULE');
export const DISCONNECT_SERVICE_RESOURCE_TYPE = createFormAction('DISCONNECT_SERVICE_RESOURCE_TYPE');
export const EDIT_SERVICE_PRICEVIEW_TYPE = createFormAction('EDIT_SERVICE_PRICEVIEW_TYPE');
export const CREATE_SERVICE = createFormAction('CREATE_SERVICE');
export const DELETE_SERVICE = createFormAction('DELETE_SERVICE');
export const COPY_SERVICE = createFormAction('COPY_SERVICE');
export const FETCH_SCHEDULE_TYPES = createFormAction('FETCH_SCHEDULE_TYPES');
export const FETCH_SERVICE_GROUPS = createFormAction('FETCH_SERVICE_GROUPS');
export const FETCH_PRICE_VIEW_TYPES = createFormAction('FETCH_PRICE_VIEW_TYPES');
export const FETCH_CALCULATION_TYPES = createFormAction('FETCH_CALCULATION_TYPES');
// Services
export const FETCH_SUPPORT_TICKETS = createFormAction('FETCH_SUPPORT_TICKETS');
export const EDIT_SUPPORT_TICKET = createFormAction('EDIT_SUPPORT_TICKET');
export const CREATE_SUPPORT_TICKET = createFormAction('CREATE_SUPPORT_TICKET');
export const DELETE_SUPPORT_TICKET = createFormAction('DELETE_SUPPORT_TICKET');
export const FETCH_SUPPORT_TICKET_AREAS = createFormAction('FETCH_SUPPORT_TICKET_AREAS');
export const FETCH_SUPPORT_TICKET_TYPES = createFormAction('FETCH_SUPPORT_TICKET_TYPES');
export const FETCH_SUPPORT_TICKET_STATUSES = createFormAction('FETCH_SUPPORT_TICKET_STATUSES');
export const FETCH_SUPPORT_TICKET_COMMENTS = createFormAction('FETCH_SUPPORT_TICKET_COMMENTS');
export const CREATE_SUPPORT_TICKET_COMMENT = createFormAction('CREATE_SUPPORT_TICKET_COMMENT');
export const DELETE_SUPPORT_TICKET_COMMENT = createFormAction('DELETE_SUPPORT_TICKET_COMMENT');
export const FETCH_SUPPORT_TICKET_ATTACHMENTS = createFormAction(
    'FETCH_SUPPORT_TICKET_ATTACHMENTS'
);
export const CREATE_SUPPORT_TICKET_ATTACHMENT = createFormAction(
    'CREATE_SUPPORT_TICKET_ATTACHMENT'
);
// New booking
export const RESET_BOOKING = 'RESET_BOOKING';
export const CHANGE_BOOKING_NAVIGATION_DATE = 'CHANGE_BOOKING_NAVIGATION_DATE';
export const CHANGE_BOOKING_WEEK = 'CHANGE_BOOKING_WEEK';
export const SEARCH_CUSTOMERS = createFormAction('SEARCH_CUSTOMERS');
export const FETCH_AVAILABLE_TIMES = createFormAction('FETCH_AVAILABLE_TIMES');
export const FETCH_NEXT_FREE_TIME = createFormAction('FETCH_NEXT_FREE_TIME');
export const FETCH_SERVICE_WITH_PRICE = createFormAction('FETCH_SERVICE_WITH_PRICE');
export const UPDATE_SERVICE_QUANTITY = createFormAction('UPDATE_SERVICE_QUANTITY');
// Bookings
export const FETCH_BOOKINGS = createFormAction('FETCH_BOOKINGS');
export const FETCH_BOOKING_DETAILS = createFormAction('FETCH_BOOKING_DETAILS');
export const EDIT_BOOKING = createFormAction('EDIT_BOOKING');
export const APPROVE_BOOKING = createFormAction('APPROVE_BOOKING');
export const DECLINE_BOOKING = createFormAction('DECLINE_BOOKING');
export const CANCEL_BOOKING = createFormAction('CANCEL_BOOKING');
export const FETCH_BOOKING_LOGS = createFormAction('FETCH_BOOKING_LOGS');
export const FETCH_BOOKING_REPORT_OPTIONS = createFormAction('FETCH_BOOKING_REPORT_OPTIONS');
export const SEND_MESSAGE_TO_CUSTOMER = createFormAction('SEND_MESSAGE_TO_CUSTOMER');
export const SEND_CONFIRMATION = createFormAction('SEND_CONFIRMATION');
export const PRINT_RECEIPT = createFormAction('PRINT_RECEIPT');
export const SEND_RECEIPT = createFormAction('SEND_RECEIPT');
export const MARK_BOOKING_AS_PAID = createFormAction('MARK_BOOKING_AS_PAID');
export const FETCH_COLLIDING_EVENTS = createFormAction('FETCH_COLLIDING_EVENTS');
export const FETCH_BOOKING_STATUSES = createFormAction('FETCH_BOOKING_STATUSES');
export const SHOW_CANCEL_BOOKING_MODAL = 'SHOW_CANCEL_BOOKING_MODAL';
export const HIDE_CANCEL_BOOKING_MODAL = 'HIDE_CANCEL_BOOKING_MODAL';
export const CREATE_BOOKED_RESOURCE = createFormAction('CREATE_BOOKED_RESOURCE');
export const EDIT_BOOKED_RESOURCE = createFormAction('EDIT_BOOKED_RESOURCE');
export const DELETE_BOOKED_RESOURCE = createFormAction('DELETE_BOOKED_RESOURCE');
export const EDIT_BOOKED_QUANTITIES = createFormAction('EDIT_BOOKED_QUANTITIES');
export const FETCH_AVAILABLE_RESOURCES = createFormAction('FETCH_AVAILABLE_RESOURCES');
export const REFUND_PAYMENT = createFormAction('REFUND_PAYMENT');
export const SEND_PAYMENT_REQUEST = createFormAction('SEND_PAYMENT_REQUEST');
export const REASSIGN_BOOKED_RESOURCE = createFormAction('REASSIGN_BOOKED_RESOURCE');
export const EXPORT_BOOKINGS = createFormAction('EXPORT_BOOKINGS');

// Confirmation
export const CONFIRM = 'CONFIRM';
export const SHOW_CONFIRMATION = 'SHOW_CONFIRMATION';
export const HIDE_CONFIRMATION = 'HIDE_CONFIRMATION';
export const CONFIRM_YES = 'CONFIRM_YES';
export const CONFIRM_NO = 'CONFIRM_NO';
// Images
export const UPLOAD_IMAGE = createFormAction('UPLOAD_IMAGE');
export const RESET_IMAGE_UPLOADER = 'RESET_IMAGE_UPLOADER';
export const OPEN_IMAGE_UPLOAD_MODAL = 'OPEN_IMAGE_UPLOAD_MODAL';
export const CLOSE_IMAGE_UPLOAD_MODAL = 'CLOSE_IMAGE_UPLOAD_MODAL';
// Statistics
export const FETCH_STATISTICS = createFormAction('FETCH_STATISTICS');
// Messages
export const RESEND_MESSAGE = createFormAction('RESEND_MESSAGE');
export const FETCH_MESSAGE_LOG = createFormAction('FETCH_MESSAGE_LOG');
export const FETCH_MESSAGE_DASHBOARD = createFormAction('FETCH_MESSAGE_DASHBOARD');
export const FETCH_MESSAGE_TEMPLATES = createFormAction('FETCH_MESSAGE_TEMPLATES');
export const CREATE_MESSAGE_TEMPLATE = createFormAction('CREATE_MESSAGE_TEMPLATE');
export const EDIT_MESSAGE_TEMPLATE = createFormAction('EDIT_MESSAGE_TEMPLATE');
export const DELETE_MESSAGE_TEMPLATE = createFormAction('DELETE_MESSAGE_TEMPLATE');
export const FETCH_MESSAGE_TEMPLATE_TYPES = createFormAction('FETCH_MESSAGE_TEMPLATE_TYPES');
export const FETCH_MESSAGE_FIELD_TRANSLATIONS = createFormAction(
    'FETCH_MESSAGE_FIELD_TRANSLATIONS'
);
export const TEST_MESSAGE_TEMPLATE = createFormAction('TEST_MESSAGE_TEMPLATE');
// Newsletters
export const SEND_NEWSLETTER = createFormAction('SEND_NEWSLETTER');
export const RESEND_NEWSLETTER = createFormAction('RESEND_NEWSLETTER');
export const FETCH_NEWSLETTER_LOG = createFormAction('FETCH_NEWSLETTER_LOG');
export const FETCH_NEWSLETTER_TEMPLATES = createFormAction('FETCH_NEWSLETTER_TEMPLATES');
export const FETCH_NEWSLETTER_CUSTOMERS = createFormAction('FETCH_NEWSLETTER_CUSTOMERS');
export const CREATE_NEWSLETTER_TEMPLATE = createFormAction('CREATE_NEWSLETTER_TEMPLATE');
export const EDIT_NEWSLETTER_TEMPLATE = createFormAction('EDIT_NEWSLETTER_TEMPLATE');
export const DELETE_NEWSLETTER_TEMPLATE = createFormAction('DELETE_NEWSLETTER_TEMPLATE');
export const FETCH_NEWSLETTER_FIELD_TRANSLATIONS = createFormAction(
    'FETCH_NEWSLETTER_FIELD_TRANSLATIONS'
);
export const FETCH_NEWSLETTER_SEND_METHODS = createFormAction('FETCH_NEWSLETTER_SEND_METHODS');
export const FETCH_NEWSLETTER_EMAIL_TEMPLATES = createFormAction(
    'FETCH_NEWSLETTER_EMAIL_TEMPLATES'
);
export const TEST_NEWSLETTER_TEMPLATE = createFormAction('TEST_NEWSLETTER_TEMPLATE');
// Booking printout
export const FETCH_BOOKING_PRINTOUT_TEMPLATES = createFormAction(
    'FETCH_BOOKING_PRINTOUT_TEMPLATES'
);
export const CREATE_BOOKING_PRINTOUT_TEMPLATE = createFormAction(
    'CREATE_BOOKING_PRINTOUT_TEMPLATE'
);
export const EDIT_BOOKING_PRINTOUT_TEMPLATE = createFormAction('EDIT_BOOKING_PRINTOUT_TEMPLATE');
export const DELETE_BOOKING_PRINTOUT_TEMPLATE = createFormAction(
    'DELETE_BOOKING_PRINTOUT_TEMPLATE'
);
export const FETCH_BOOKING_PRINTOUT_FIELD_TRANSLATIONS = createFormAction(
    'FETCH_BOOKING_PRINTOUT_FIELD_TRANSLATIONS'
);
export const TEST_BOOKING_PRINTOUT_TEMPLATE = createFormAction('TEST_BOOKING_PRINTOUT_TEMPLATE');
// Synchronization
export const FETCH_GOOGLE_CALENDAR_TOKENS = createFormAction('FETCH_GOOGLE_CALENDAR_TOKENS');
export const CREATE_GOOGLE_CALENDAR_TOKEN = createFormAction('CREATE_GOOGLE_CALENDAR_TOKEN');
export const DELETE_GOOGLE_CALENDAR_TOKEN = createFormAction('DELETE_GOOGLE_CALENDAR_TOKEN');
export const DELETE_GOOGLE_CALENDAR_TOKEN_CONFIRMED = createFormAction('DELETE_GOOGLE_CALENDAR_TOKEN_CONFIRMED');
export const FETCH_ICAL_TOKENS = createFormAction('FETCH_ICAL_TOKENS');
export const CREATE_ICAL_TOKEN = createFormAction('CREATE_ICAL_TOKEN');
export const DELETE_ICAL_TOKEN = createFormAction('DELETE_ICAL_TOKEN');
export const DELETE_ICAL_TOKEN_CONFIRMED = createFormAction('DELETE_ICAL_TOKEN_CONFIRMED');
// Logging
export const LOG_ERRORS = createFormAction('LOG_ERRORS');
// Verification
export const FETCH_VERIFY_EMAIL = createFormAction('FETCH_VERIFY_EMAIL');

// Codelock
export const FETCH_CODELOCK_SETTINGS = createFormAction('FETCH_CODELOCK_SETTINGS');
export const EDIT_CODELOCK_SETTINGS = createFormAction('EDIT_CODELOCK_SETTINGS');
export const CREATE_CODELOCK_SETTINGS = createFormAction('CREATE_CODELOCK_SETTINGS');

export const EDIT_AXEMA_SETTINGS = createFormAction('EDIT_AXEMA_SETTINGS');
export const FETCH_AXEMA_SETTINGS = createFormAction('FETCH_AXEMA_SETTINGS');
export const CREATE_AXEMA_SETTINGS = createFormAction('CREATE_AXEMA_SETTINGS');

export const EDIT_SIEDLE_SETTINGS = createFormAction('EDIT_SIEDLE_SETTINGS');
export const FETCH_SIEDLE_SETTINGS = createFormAction('FETCH_SIEDLE_SETTINGS');
export const CREATE_SIEDLE_SETTINGS = createFormAction('CREATE_SIEDLE_SETTINGS');

export const FETCH_VANDERBILT_SETTINGS = createFormAction('FETCH_VANDERBILT_SETTINGS');
export const EDIT_VANDERBILT_SETTINGS = createFormAction('EDIT_VANDERBILT_SETTINGS');
export const CREATE_VANDERBILT_SETTINGS = createFormAction('CREATE_VANDERBILT_SETTINGS');

export const EDIT_PARAKEY_SETTINGS = createFormAction('EDIT_PARAKEY_SETTINGS');
export const FETCH_PARAKEY_SETTINGS = createFormAction('FETCH_PARAKEY_SETTINGS');
export const CREATE_PARAKEY_SETTINGS = createFormAction('CREATE_PARAKEY_SETTINGS');

export const EDIT_AMIDO_SETTINGS = createFormAction('EDIT_AMIDO_SETTINGS');
export const FETCH_AMIDO_SETTINGS = createFormAction('FETCH_AMIDO_SETTINGS');
export const CREATE_AMIDO_SETTINGS = createFormAction('CREATE_AMIDO_SETTINGS');

export const FETCH_TELKEY_SETTINGS = createFormAction('FETCH_TELKEY_SETTINGS');
export const EDIT_TELKEY_SETTINGS = createFormAction('EDIT_TELKEY_SETTINGS');
export const CREATE_TELKEY_SETTINGS = createFormAction('CREATE_TELKEY_SETTINGS');

export const FETCH_ZESEC_SETTINGS = createFormAction('FETCH_ZESEC_SETTINGS');
export const EDIT_ZESEC_SETTINGS = createFormAction('EDIT_ZESEC_SETTINGS');
export const CREATE_ZESEC_SETTINGS = createFormAction('CREATE_ZESEC_SETTINGS');

export const FETCH_RCO_M5_SETTINGS = createFormAction('FETCH_RCO_M5_SETTINGS');
export const EDIT_RCO_M5_SETTINGS = createFormAction('EDIT_RCO_M5_SETTINGS');
export const CREATE_RCO_M5_SETTINGS = createFormAction('CREATE_RCO_M5_SETTINGS');

export const FETCH_RCO_ENABLA_SETTINGS = createFormAction('FETCH_RCO_ENABLA_SETTINGS');
export const EDIT_RCO_ENABLA_SETTINGS = createFormAction('EDIT_RCO_ENABLA_SETTINGS');
export const CREATE_RCO_ENABLA_SETTINGS = createFormAction('CREATE_RCO_ENABLA_SETTINGS');

export const EDIT_ACCESSY_SETTINGS = createFormAction('EDIT_ACCESSY_SETTINGS');
export const FETCH_ACCESSY_SETTINGS = createFormAction('FETCH_ACCESSY_SETTINGS');
export const CREATE_ACCESSY_SETTINGS = createFormAction('CREATE_ACCESSY_SETTINGS');

export const AUTHENTICATE_UPDATE_TOKENS = createFormAction('AUTHENTICATE_UPDATE_TOKENS');
export const KEYCLOAK_UPDATE_TOKENS = 'KEYCLOAK_UPDATE_TOKENS';
export const KEYCLOAK_API_LOGOUT = createFormAction('KEYCLOAK_API_LOGOUT');

export const WAIT_FOR_AUTHENTICATE_REPLACE = createFormAction('WAIT_FOR_AUTHENTICATE_REPLACE');
export const AUTHENTICATE_RE_LOGIN = createFormAction('AUTHENTICATE_RE_LOGIN');

export const DELETE_USER = createFormAction('DELETE_USER');

// agreement
export const SHOW_AGREEMENT_MODAL = 'SHOW_AGREEMENT_MODAL';
export const HIDE_AGREEMENT_MODAL = 'HIDE_AGREEMENT_MODAL';
export const ACCEPT_AGREEMENT = 'ACCEPT_AGREEMENT';

// Access keys
export const FETCH_ACCESSKEYTYPES = createFormAction('FETCH_ACCESSKEYTYPES');
export const DELETE_CUSTOMER_ACCESS_KEY = createFormAction('DELETE_CUSTOMER_ACCESS_KEY');

// E-accounting
export const FETCH_EACCOUNTING_TOKEN = createFormAction('FETCH_EACCOUNTING_TOKEN');
export const FETCH_EACCOUNTING_ARTICLES = createFormAction('FETCH_EACCOUNTING_ARTICLES');
export const FETCH_EACCOUNTING_PRICES = createFormAction('FETCH_EACCOUNTING_PRICES');
export const POST_EACCOUNTING_PRICES = createFormAction('POST_EACCOUNTING_PRICES');
export const UPDATE_EACCOUNTING_PRICE = 'UPDATE_EACCOUNTING_PRICE';
export const UPSERT_EACCOUNTING_PRICE = 'UPSERT_EACCOUNTING_PRICE';
export const UPDATE_EACCOUNTING_PRICE_EXTERNAL_REF = 'UPDATE_EACCOUNTING_PRICE_EXTERNAL_REF';
export const EACCOUNTING_POST_AUTH_CODE = createFormAction('FETCH_EACCOUNTING_POST_AUTH_CODE');
export const FETCH_EACCOUNTING_PRICE_MAPPINGS = createFormAction('FETCH_EACCOUNTING_PRICE_MAPPINGS');
export const REMOVE_EACCOUNTING_PRICE_MAPPING = 'REMOVE_EACCOUNTING_PRICE_MAPPING';
// export const CREATE_EACCOUNTING_INVOICE = createFormAction('CREATE_EACCOUNTING_INVOICE');
export const createInvoice = createAction('invoice/createInvoice/request', (payload: CreateInvoiceType ) => {
    return {
        payload
    };
});
export const CREATE_EACCOUNTING_INVOICE_CANCEL = 'CREATE_EACCOUNTING_INVOICE_CANCEL';
export const startInvoiceCreate = createAction('invoice/createInvoice/start', (bookingId: number) => {
    return {
        payload: bookingId
    }
});
export const fullfillnvoiceCreate = createAction('invoice/createInvoice/fulfill')
export const showInvoiceCreateModal = createAction('invoice/createInvoice/fulfill/show');
export const hideInvoiceCreateModal = createAction('invoice/createInvoice/fulfill/hide');
export const hideInvoiceSuccessModal = createAction('invoice/createInvoice/successModal/hide');

export const FETCH_PAYMENT_TERMS = createFormAction('FETCH_PAYMENT_TERMS');



export const removePriceArticleMapping = createAction(
    'eaccounting/REMOVE_PRICE_ARTICLE_MAPPING',
    (priceId: number) => {
        return {
            payload: priceId
        }
    }
);

export const resetUpdatedPrices = createAction('eaccounting/RESET_UPDATED_PRICES');

// Auth
export const authSuccess = createAction('authenticate/success');
export const authLogout = createAction('authenticate/logout');
export const authFullLogout = createAction('authenticate/fullLogout');
export const authFail = createAction('authenticate/fail', (err: any) => {
    return {
        payload: err,
    };
});
export const authoriseSuccess = createAction('authorize/success', (data: any) => {
    return {
        payload: data
    }
});

export const authoriseFail = createAction('authorize/fail', (data: any) => {
    return {
        payload: data
    }
});


type SendCancelQuestionaireData = {
    [key in string]: any
}

export const sendCancelQuestionaire = createFormAction('cancelLicenseQuestionaire');

export const cancelQuestionaireProceed = createAction('cancelLicenseQuestionaire/proceed', (data?: any) => {
    return {
        payload: data
    }
});
export const cancelQuestionaireOpenModal = createAction('cancelLicenseQuestionaire/modal/open');
export const cancelQuestionaireCloseModal = createAction('cancelLicenseQuestionaire/modal/close');

export const updateEaccountingSettings = createFormAction('eaccounting/settings/update');
export const cancelQuestionaireSkip = createAction('cancelLicenseQuestionaire/skip');
export const eaccountingStartAuthenticate = createAction('eaccounting/authenticate/start');

// CREATE RESOURCE WIZARD
export const CREATE_RESOURCE_TYPE_WIZARD = createFormAction('CREATE_RESOURCE_TYPE_WIZARD');
export const EDIT_RESOURCE_TYPE_WIZARD = createFormAction('EDIT_RESOURCE_TYPE_WIZARD');

export const FETCH_SERVICE_DURATION_TYPES = createFormAction('FETCH_SERVICE_DURATION_TYPES');


export const showCorrelationIdModal = createAction('correlationid/modal/show', (correlationId: string) => ({ payload: correlationId }));
export const hideCorrelationIdModal = createAction('correlationid/modal/hide');


export const addBlockingError = createAction(
    'correlationid/addBlockingError',
    (error: Error) => {
        const errorText = error.message;

        return {
            payload: {
                text: errorText
            },
        };
    }
);

export const addInformativeError = createAction(
    'correlationid/addInformativeError',
    (error: AxiosError) => {
        const errorText = error.message;
        const correlationId = error.response?.headers['X-Correlation-Id'];

        return {
            payload: {
                text: errorText,
                correlationId
            },
        };
    }
);

export const addNonBlockingError = createAction(
    'correlationid/addNonBlockingError',
    ({
        message,
        correlationId,
        responseData,
        requestData,
        method,
        url
    } : {
        message?: string,
        correlationId?: string,
        responseData?: any,
        requestData?: any,
        method: string,
        url: string

    }) => {

        return {
            payload: {
                text: message,
                correlationId,
                ...(method && url ? { endpoint: { url, method }} : {}),
                responseData,
                requestData
            },
        };
    }
);

// export const calculatePriceRequest = createAction(
//     'services/calculateprice/request',
//     (data: definitions['CalculateTotalPriceOnService']) => {
//         return {
//             payload: data,
//         };
//     }
// );

// export const calculatePriceSuccess = createAction(
//     'services/calculateprice/success',
//     (key: string, data: TotalPriceInformationResponse) => {
//         return {
//             payload: {
//                 key,
//                 data
//             },
//         };
//     }
// );

export const calculatePriceFail = createAction('services/calculateprice/fail', (data) => {
    return {
        payload: data,
    };
});

export const ADD_TRANSACTION = createFormAction('ADD_TRANSACTION');

export const PRINT_PROMOCODE_RECEIPT = createFormAction('PRINT_PROMOCODE_RECEIPT');
export const SEND_PROMOCODE_RECEIPT = createFormAction('SEND_PROMOCODE_RECEIPT');

export const CHANGE_QUANTITY = createFormAction('CHANGE_QUANTITY');